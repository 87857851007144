import React, { useState, useEffect, useContext, useRef } from "react";
import http, { getErrorMessage } from "../utility/http";

// Contexts
import { UserContext } from "../context/UserContext";

const NewFolderForm = ({ showForm, setShowForm, logAction }) => {
  const [processing, setProcessing] = useState(false);
  const [newFolder, setNewFolder] = useState({});
  const nameInput = useRef();

  // Get user context
  const { user, setUser } = useContext(UserContext);

  // Focus on the text field when the form is shown
  useEffect(() => {
    if (showForm) {
      nameInput.current.focus();
    }
  }, [showForm, nameInput]);

  // Window event handlers
  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      // Cancel new folder creation
      if (e.keyCode === 27 && showForm === true) {
        setNewFolder({});
        setShowForm(false);
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);

    // Remove event handlers on destroy
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
    };
  }, [showForm, setShowForm, setNewFolder]);

  const handleNewFolderNameInput = (e) => {
    const copyOfNewFolder = { ...newFolder };
    copyOfNewFolder.name = e.target.value;
    setNewFolder(copyOfNewFolder);
  };

  const saveNewFolder = (e) => {
    if (newFolder.name && !processing) {
      setProcessing(true);

      http.post("/user/folder/", newFolder)
        .then(resp => {
          if (resp.data.success === false) {
            alert(resp.data.message);
          } else {
            // Add the folder to the user object
            const copyOfUser = { ...user };
            copyOfUser.folders.unshift(resp.data);
            setUser(copyOfUser);

            // Reset the form and hide it
            setNewFolder({});
            setShowForm(false);
            logAction("folder", "new");
          }

          setProcessing(false);
        })
        .catch((error) => {
          alert(getErrorMessage(error));
        });
    }

    e.preventDefault();
  };

  if (!showForm) {
    return null;
  }

  return (
    <li className="sidebar-list-item">
      <form name="new_folder" className={`sidebar-form ${processing ? 'sidebar-form--loading' : ''}`} onSubmit={saveNewFolder} noValidate autoComplete="off">
        <input type="text" id="folder_name" name="folder_name" ref={nameInput} placeholder="New folder name" onChange={handleNewFolderNameInput} defaultValue={newFolder.name} className="sidebar-input-field" />
        <input type="submit" value="Save" className="btn secondary sidebar-btn" />
      </form>
    </li>
  );
};

export default NewFolderForm;


import React, { useState, useEffect } from "react";
import { withRouter, Navigate, Link } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Header from "../components/Header";
import Banner from "../components/Banner";

const ForgotPassword = ({ setSection }) => {
  const [bannerTitle, setBannerTitle] = useState("Forgot your password?");
  const [data, setData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setSection("login");
  }, [setSection]);

  const handleInputChange = ({ target }) => {
    setData((prevData) => ({ ...prevData, [target.name]: target.value }));
  };

  const formError = () => {
    return error ? <div className="form-error">{error}</div> : null;
  };

  const Submit = (event) => {
    setError("");
    event.preventDefault();

    if (checkForm(event.target)) {
      setLoading(true);

      http.post("/user/forgot-password/", data).then((resp) => {
        setLoading(false);

        if (resp.data.success === false) {
          setError(resp.data.message);
        } else {
          setSuccess(true);
          setBannerTitle("Success");
        }
      }).catch((err) => {
        setLoading(false);
        setError(getErrorMessage(err));
      });
    }
  };

  // Redirect logged-in users to the main app
  if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
    return <Navigate to="/" />;
  }

  const FormSuccess = () => {
    if (success) {
      return (
        <div className="col-100 half-padding">
          <p className="bordered-top centered"><strong>Please <u>check your email</u> for a link to change your password.</strong></p>
          <p className="centered">(•‿•)</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Header section="forgot_password" />
      <Banner title={bannerTitle} />

      <section className="standard-content">
        <div className="inner-column very-narrow half-padding">

          <form name="forgot_password_form" onSubmit={Submit} noValidate className={`${loading ? 'loading' : ''} ${success ? 'hidden' : ''}`}>

            <div className="col-100 half-padding">
              <label htmlFor="field_email" className="label">Email Address</label>
              <input name="email" type="email" id="field_email" className="field" autoFocus onChange={handleInputChange} value={data.email} required data-error-text="Please enter your email address" />
            </div>

            <div className="col-100 half-padding centered">
              {formError()}
              <input type="submit" name="submit" value="Send Password Reset Code" className="btn lg push-submit small-mobile-full" />
              <div>
                <Link to="/login" className="subtle-link">Log In</Link>
              </div>
            </div>

          </form>

          <FormSuccess />

        </div>
      </section>

    </div>
  );
};

export default ForgotPassword;


import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Contexts
import { UserContext } from "../context/UserContext";

// UI
import ProfileInfo from "./settings/ProfileInfo";
import ChangeUsername from "./settings/ChangeUsername";
import ChangePassword from "./settings/ChangePassword";
import ExportSnippets from "./settings/ExportSnippets";
import Notifications from "./settings/Notifications";
import ManageSubscription from "./settings/ManageSubscription";
import SubscriptionCancelled from "./settings/SubscriptionCancelled";
import Support from "./settings/Support";
import Loading from "../components/Loading";

function Settings(props) {
  // Get user context
  const { userLoading, user } = useContext(UserContext);
  const params = useParams();

  const navigate = useNavigate();
  //const { tab } = match.params;
  //const { show_mobile_ui, setShowMobileSidebarAndList } = props;

  //console.log(params);
  //console.log(props);

  const { tab } = params;
  const { show_mobile_ui } = props;
  const { setShowMobileSidebarAndList } = props;

  const allowedTabs = [
    "profile-info",
    "change-password",
    "change-username",
    "notifications",
    "manage-subscription",
    "subscription-cancelled",
    "support"
  ];

  useEffect(() => {
    if (!userLoading) {
      if (user.pro) {
        allowedTabs.push("export-snippets");
      }

      if (allowedTabs.indexOf(tab) < 0) {
        navigate("/");
      }
    }
  }, [user, userLoading, allowedTabs, tab ]);

  const ExportSnippetsTab = () => {
    if (!userLoading && user.pro) {
      return (
        <li className="list-column-list-item">
          <Link to="/settings/export-snippets" className={tab === 'export-snippets' ? 'active' : ''}>
            <span className="title">
              Export<span className="hide-medium-mobile"> Snippets</span>
            </span>
          </Link>
        </li>
      );
    }

    return null;
  };

  return (
    <section className="main settings-panel no-padding">
      <div className="main-section">
        <div className="main-title-bar">
          <h3>Your Account</h3>
        </div>

        <section className={`list-column narrow ${show_mobile_ui ? 'show-mobile' : ''}`}>
          <Loading loading={userLoading} />

          <div className={`list-column-inner fades-in ${userLoading ? 'fades-in--hidden' : ''}`}>
            <ul className="list-column-list">
              <li className="list-column-list-item">
                <Link to="/settings/profile-info" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'profile-info' ? 'active' : ''}>
                  <span className="title">Profile Info</span>
                </Link>
              </li>

             <li className="list-column-list-item">
                <Link to="/settings/change-username" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'change-username' ? 'active' : ''}>
                  <span className="title">
                    <span className="hide-medium-mobile">Change </span>Username
                  </span>
                </Link>
              </li>

              <li className="list-column-list-item">
                <Link to="/settings/change-password" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'change-password' ? 'active' : ''}>
                  <span className="title">
                    <span className="hide-medium-mobile">Change </span>Password
                  </span>
                </Link>
              </li>

              <ExportSnippetsTab />

              <li className="list-column-list-item">
                <Link to="/settings/manage-subscription" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'manage-subscription' ? 'active' : ''}>
                  <span className="title">SnipSave Pro</span>
                </Link>
              </li>

              <li className="list-column-list-item">
                <Link to="/settings/notifications" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'notifications' ? 'active' : ''}>
                  <span className="title">
                    <span className="hide-medium-mobile">Notifications</span>
                  </span>
                </Link>
              </li>

              <li className="list-column-list-item" ng-if="userIsPro">
                <Link to="/settings/support" onClick={() => setShowMobileSidebarAndList(false)} className={tab === 'support' ? 'active' : ''}>
                  <span className="title">Contact Support</span>
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <div className="main-section-content has-side-nav">
          {tab === "profile-info" ? <ProfileInfo /> : null}
          {tab === "change-username" ? <ChangeUsername /> : null}
          {tab === "change-password" ? <ChangePassword /> : null}
          {tab === "export-snippets" ? <ExportSnippets /> : null}
          {tab === "notifications" ? <Notifications /> : null}
          {tab === "manage-subscription" ? <ManageSubscription /> : null}
          {tab === "subscription-cancelled" ? <SubscriptionCancelled /> : null}
          {tab === "support" ? <Support /> : null}
        </div>
      </div>
    </section>
  );
};

export default Settings;


import React, { useState, useEffect } from "react";
import { withRouter, Navigate, Link, useNavigate } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Header from "../components/Header";
import Banner from "../components/Banner";

const Login = ({ location, setSection, logAction, history }) => {
  const [bannerTitle, setBannerTitle] = useState("Log In");
  const [passwordUpdateRequired, setPasswordUpdateRequired] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setSection("login");
  }, [setSection]);

  const handleInputChange = ({ target }) => {
    setData((prevData) => ({ ...prevData, [target.name]: target.value }));
  };

  const formError = () => {
    return error ? <div className="form-error">{error}</div> : null;
  };

  const Submit = (event) => {
    //const redirect = location.search.split("?redirect=")[1];
    //const navigate = useNavigate();
    setError("");
    event.preventDefault();

    if (checkForm(event.target)) {
      setLoading(true);

      http.post("/user/login/", data).then((resp) => {
        setLoading(false);

        if (resp.data.success === false) {
          setError(resp.data.message);
        } else {
          if (resp.data.error && resp.data.error === "needs_password_reencryption") {
            setBannerTitle("Welcome to SnipSave 3");
            setPasswordUpdateRequired(true);
          } else {
            localStorage.setItem("access_token", resp.data.access_token);
            localStorage.setItem("refresh_token", resp.data.refresh_token);
            localStorage.setItem("username", resp.data.username);
            logAction("user", "login");
          //history.push(redirect ? redirect : "/");
          //navigate(redirect ? redirect : "/");
          }
        }
      }).catch((err) => {
        setLoading(false);
        setError(getErrorMessage(err));
      });
    }
  };

  // Redirect logged-in users to the main app
  if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
    return <Navigate to="/" />;
  }

  const PassUpdateMessage = () => {
    if (passwordUpdateRequired) {
      return (
        <div className="col-100 half-padding">
          <h2 className="centered">Password Update Required</h2>
          <p className="centered">SnipSave has been rebuilt from the ground up. We're excited to show you a much-improved interface and some great new features.</p>
          <p className="bordered-bottom centered">All we need from you is an updated password.</p>
          <p className="centered"><strong>Please <u>check your email</u> for a link to update your password.</strong></p>
          <p className="centered">(•‿•)</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Header section="login" />
      <Banner title={bannerTitle} />

      <section className="standard-content">
        <div className="inner-column very-narrow half-padding">

          <form name="login_form" onSubmit={Submit} noValidate className={`${loading ? 'loading' : ''} ${passwordUpdateRequired ? 'hidden' : ''}`}>

            <div className="col-100 half-padding">
              <label htmlFor="field_email" className="label">Email Address</label>
              <input name="email" type="email" id="field_email" className="field" autoFocus onChange={handleInputChange} value={data.email} required data-error-text="Please enter your email address" />
            </div>

            <div className="col-100 half-padding">
              <label htmlFor="field_password" className="label">Password</label>
              <input name="password" type="password" id="field_password" className="field" onChange={handleInputChange} value={data.password} required data-error-text="Please enter your password" />
            </div>

            <div className="col-100 half-padding centered">
              {formError()}
              <input type="submit" name="submit" value="Log In" className="btn lg push-submit small-mobile-full" />
              <div>
                <Link to="/forgot-password" className="subtle-link">I forgot my password</Link>
              </div>
            </div>

          </form>

          <PassUpdateMessage />

        </div>
      </section>
    </div>
  );
};

//export default withRouter(Login);
export default Login;


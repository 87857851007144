import React, { useContext, useEffect, useState } from "react";
import http, { getErrorMessage } from "../../../utility/http";
import checkForm from "../../../utility/validator";

// Contexts
import { UserContext } from "../../../context/UserContext";

// UI
import Benefits from "./Benefits";

const SubscribeForm = (props) => {
  // Get user context
  const userContext = useContext(UserContext);
  const { user, setUser } = userContext;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [years, setYears] = useState([]);

  // Initial setup
  useEffect(() => {
    // Setup year option dropdown
    const currentYear = new Date().getFullYear();
    const tempYears = [];

    for (let i = 0; i < 21; i++) {
      tempYears.push(currentYear + i);
    }

    setYears(tempYears);
  }, []);

  // Stripe setup
  window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUB_KEY);
  //window.Stripe.setPublishableKey("pk_live_AzZbWplHtGrVzBWuten2HNa4");

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function submit(event) {
    event.preventDefault();
    setError(null);

    if (checkForm(event.target)) {
      document.activeElement.blur();
      setLoading(true);

      const subscribeData = {
        user_id: user.id,
        user_email: user.email,
        stripe_token: "",
        cc_id: "",
        last_four_of_cc: "",
      };

      window.Stripe.createToken(event.target, (resp, data) => {
        if (data.error) {
          setError(data.error.message);
          setLoading(false);
        } else {
          subscribeData.stripe_token = data.id;
          subscribeData.cc_id = data.card.id;
          subscribeData.last_four_of_cc = data.card.last4;

          http.put("/user/subscribe/", subscribeData)
            .then((resp) => {
              console.log(resp);
              if (resp.data.success === false) {
                setError(resp.data.message);
                setLoading(false);
              } else {
                // Update the auth tokens
                localStorage.setItem("access_token", resp.data.access_token);
                localStorage.setItem("refresh_token", resp.data.refresh_token);

                // Update the user object
                const copyOfUser = { ...user };
                copyOfUser.pro = true;
                copyOfUser.snippet_limit = 0;
                copyOfUser.subscription = resp.data.subscription;
                setUser(copyOfUser);
                //props.logAction("user", "subscription_create");
              }
            })
            .catch((error) => {
              setError(getErrorMessage(error));
            });
        }
      });
    }
  }

  return (
    <div className="max-width-700">

      <div className="col-100 half-padding">
        <h3>Upgrade to SnipSave Pro</h3>
        <p><strong className="almost-black">$4.99/month</strong> - No hidden fees, cancel at any time.</p>
        <div className="hr less-top"></div>
      </div>

      <div className="narrow-inner-content">
        <form name="account_upgrade_form" onSubmit={submit} className={`max-width-form off-center-alt--wide-btn less-mb ${loading ? 'loading' : ''}`} noValidate>

          <div className="col-100 half-padding">

            <input type="hidden" name="stripe_name" data-stripe="name" value={`${user.first_name} ${user.last_name}`} className="field" />
            <input type="hidden" name="field_stripe_description" data-stripe="description" value="SnipSave Pro" className="field" />

            <label htmlFor="field_cc_number" className="label">Credit/Debit Card Number</label>
            <input name="cc_number" type="text" id="field_cc_number_update" minLength="10" size="20" maxLength="20" data-stripe="number" className="field" required data-error-text="Please enter a credit card number" />
          </div>

          <div className="col-65 tablet-65 mobile-100 half-padding">
            <label htmlFor="field_exp_month" className="label">Expiration</label>

            <div className="select-wrap short-field first-short-field">
              <select name="exp_month" id="field_exp_month_update" data-stripe="exp-month" className="field exp_month" required data-error-text="">
                <option value="">MM</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>{month < 10 ? `0${month}` : month}</option>
                ))}
              </select>
            </div>

            <div className="select-wrap short-field">
              <select name="exp_year" id="field_exp_year_update" data-stripe="exp-year" placeholder="YYYY" className="field exp_year" required data-error-text="">
                <option value="">YYYY</option>
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>

          </div>

          <div className="col-35 tablet-35 mobile-100 half-padding no-left-padding">
            <label htmlFor="field_cvc" className="label">Security Code</label>
            <input name="cvc" type="text" id="field_cvc_update" minLength="3" size="4" maxLength="4" data-stripe="cvc" className="field short-field short-field-full-until-mobile" required data-error-text="" />
          </div>

          <div className="col-100 half-padding">
            {formError()}
            <input type="submit" name="submit" value="Upgrade to SnipSave Pro!" className="btn lg push-submit small-mobile-full" ng-class="{'success': formSuccess}" />
          </div>

        </form>
      </div>

      <div className="col-100 half-padding">
        <div className="hr"></div>
        <Benefits />
      </div>

    </div>
  );
}

export default SubscribeForm;


import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";

// Contexts
import { UserContext } from "../../context/UserContext";

// UI
import Loading from "../Loading";

function Notifications(props) {
  // Get user context
  const userContext = useContext(UserContext);
  const { userLoading, user, setUser } = userContext;

  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [processing, setProcessing] = useState(false);
  const fileInput = useRef();
  const successTimeout = useRef(false);

  // Wait for the user data to load
  useEffect(() => {
    // Once the user is done loading
    if (!userLoading) {
      const userData = {
        account_notifications: user.notifications.account,
        feature_notifications: user.notifications.features,
        marketing_notifications: user.notifications.marketing,
      };
      setData(userData);
    }
  }, [user, userLoading]);

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
    };
  }, []);

  function handleInputChange({ target }) {
    const copyOfDataObject = { ...data, [target.name]: target.value };
    setData(copyOfDataObject);
  }

  function handleFileInputChange(e) {
    const copyOfDataObject = { ...data };
    copyOfDataObject.file = e.target.files[0];
    setData(copyOfDataObject);
    e.preventDefault();
  }

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function submit(event) {
    setError(null);

    if (checkForm(event.target)) {
      document.activeElement.blur();
      setProcessing(true);

      http
        .put("/user/update-notifications/", data)
        .then((resp) => {
          if (resp.data.success === false) {
            if (resp.data.error === "email_exists") {
              setEmailExists(true);
            }
            setError(resp.data.message);
          } else {
            setSuccess(true);
            successTimeout.current = setTimeout(() => setSuccess(false), 2000);
          }
          setProcessing(false);
        })
        .catch((error) => {
          setError(getErrorMessage(error));
        });

      //props.logAction("user", "profile_update");
    }
    event.preventDefault();
  }

  const handleCheckboxChange = (checkboxName) => {
    // Update the state by toggling the value
    setData((prevData) => ({
      ...prevData,
      [checkboxName]: !prevData[checkboxName],
    }));
  };

  return (
    <div className="scrollable-area more-padding">
      <form
        name="account_basic_info_form"
        onSubmit={submit}
        className={`max-width-form alt-loading-position xxoff-center ${processing ? "loading" : ""}`}
        noValidate
      >
        <div className="col-100 small-mobile-100 half-padding">
          <input type="checkbox" checked={data.account_notifications} onChange={() => handleCheckboxChange("account_notifications")} />
          <label htmlFor="account" style={{ padding: "3px" }}>
            Account Notifications
          </label>
          <p>You agree to receive email notifications around updates to your account!</p>
        </div>
        <br />
        <div className="col-100 small-mobile-100 half-padding">
          <input type="checkbox" checked={data.feature_notifications} onChange={() => handleCheckboxChange("feature_notifications")} />
          <label htmlFor="feature" style={{ padding: "3px" }}>
            Feature Notifications
          </label>
          <p>You agree to receive email notifications around updates and new features of SnipSave!</p>
        </div>
        <br />
        <div className="col-100 small-mobile-100 half-padding">
          <input type="checkbox" id="marketing" name="marketing" value="marketing-notifications" checked={data.marketing_notifications} onChange={() => handleCheckboxChange("marketing_notifications")} />
          <label htmlFor="marketing" style={{ padding: "3px" }}>
            Marketing Notifications
          </label>
          <p>You agree to receive emails offering Snipsave Deals and other marketing materials</p>
        </div>
        <br />
        <div className="col-100 half-padding centered">
          <input type="submit" name="submit" value={success ? "Saved!" : "Update Notifications"} className={`btn lg push-submit small-mobile-full ${success ? "success" : ""}`} />
        </div>
      </form>
    </div>
  );
}

export default Notifications;


import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className="inner-column">

        <div className="centered">
          <a href="https://twitter.com/SnipSave/" target="_blank" rel="noopener noreferrer">@SnipSave on Twitter</a>
        </div>

        <div>
          &copy; {currentYear} SnipSave. All rights reserved.
        </div>

      </div>
    </footer>
  );
}

export default Footer;


import React, { useContext, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";

// UI
import gearIcon from "../img/icon-gear.svg";

// Contexts
import { UserContext } from "../context/UserContext";
import { SnippetsContext } from "../context/SnippetsContext";

const Folder = ({ location, history, folder, logAction }) => {
  //const queryParams = location.search;
  //const folderIDQueryParam = new URLSearchParams(queryParams).get("folder");
  const [showFolderActions, setShowFolderActions] = useState(null);
  const [folderDraggedOver, setFolderDraggedOver] = useState(false);

  // Get user context
  const { user, setUser } = useContext(UserContext);

  // Get snippets context
  const { changeSnippetFolder } = useContext(SnippetsContext);

  // Window event handlers
  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      // Hide folder context menu
      if (e.keyCode === 27 && showFolderActions !== null) {
        setShowFolderActions(null);
        e.preventDefault();
      }
    };

    const handleClick = (e) => {
      // Close the folder context menu when clicking anywhere on the screen
      if (showFolderActions) {
        setShowFolderActions(null);
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);
    window.addEventListener("click", handleClick);

    // Remove event handlers on destroy
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
      window.removeEventListener("click", handleClick);
    };

  }, [showFolderActions, setShowFolderActions]);

  // Rename folder
  const renameFolder = (folder) => {
    const newFolderName = window.prompt("Rename Folder", folder.name);

    if (newFolderName) {
      http.put(`/user/folder/rename/${folder.id}/`, { name: newFolderName })
        .then(resp => {
          if (resp.data.success === false) {
            alert(resp.data.message);
          } else {
            // Update the folder name on the user object
            const copyOfUser = { ...user };
            const matchingFolder = copyOfUser.folders.find((fldr) => fldr.id === folder.id);
            matchingFolder.name = newFolderName;
            setUser(copyOfUser);
            logAction("folder", "rename");
          }
        })
        .catch((error) => {
          alert(getErrorMessage(error));
        });
    }
  };

  // Delete folder
  const deleteFolder = (folder) => {
    if (window.confirm(`Are you sure you want to delete the "${folder.name}" folder?`)) {
      http.delete(`/user/folder/${folder.id}/`)
        .then(resp => {
          if (resp.data.success === false) {
            alert(resp.data.message);
          } else {
            // Update the folder name on the user object
            const copyOfUser = { ...user };
            const matchingFolderIndex = copyOfUser.folders.findIndex((fldr, idx) => fldr.id === folder.id);
            copyOfUser.folders.splice(matchingFolderIndex, 1);
            setUser(copyOfUser);
            logAction("folder", "delete");

            // If the user is currently filtering snippets by the folder they just
            // deleted then redirect them back to the main All Snippets section
            //if (folder.id === folderIDQueryParam) {
            //  history.push(`/`);
            //}
          }
        })
        .catch((error) => {
          alert(getErrorMessage(error));
        });
    }
  };

  // Handle snippet being dragged over folder
  const handleDragEnter = (e) => {
    setFolderDraggedOver(true);
  };

  // Handle snippet no longer being dragged over folder
  const handleDragLeave = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFolderDraggedOver(false);
    }
  };

  // Handle snippet being dropped into folder
  const handleDrop = (e, folder_id) => {
    const snippet_id = e.dataTransfer.getData("snippet_id");
    changeSnippetFolder(snippet_id, folder_id);
    setFolderDraggedOver(false);
    e.preventDefault();
  };

  return (
    <li className={`sidebar-list-item sidebar-list-item--folder ${folderDraggedOver ? 'droppable' : ''} ${showFolderActions === folder.id ? 'object-actions-shown' : ''}`} key={folder.id} onDragEnter={(e) => handleDragEnter(e)} onDragLeave={(e) => handleDragLeave(e)} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, folder.id)}>

      <button className="list-item-button" onClick={(e) => { setShowFolderActions(folder.id); e.preventDefault(); }}>
        <img src={gearIcon} className="icon" alt="Edit Folder" />
      </button>

      <div className="object-actions">
        <button className="object-action" onClick={() => renameFolder(folder)}>Rename</button>
        <button className="object-action object-action--warning" onClick={() => deleteFolder(folder)}>Delete</button>
      </div>

      {/*<Link to={`/?folder=${folder.id}`} className={`${queryParams === `?folder=${folder.id}` ? 'active' : ''}`}>*/}
      <Link to={`/?folder=${folder.id}`} className={`$'active' : ''}`}>
        {folder.name}
        <span className="count">{folder.count}</span>
      </Link>

    </li>
  );
}

export default Folder;


import React from "react";

// UI
import SnippetView from "../components/snippet/SnippetView";
import SnippetNew from "../components/snippet/SnippetNew";
import SnippetEdit from "../components/snippet/SnippetEdit";
import EmptyView from "../components/EmptyView";
import Settings from "../components/Settings";

const MainContent = ({ subsection, show_mobile_ui, setShowMobileSidebarAndList, logAction }) => {
  switch (subsection) {
    case "snippet_view":
      return <SnippetView logAction={logAction} />;
    case "snippet_new":
      return <SnippetNew logAction={logAction} />;
    case "snippet_edit":
      return <SnippetEdit logAction={logAction} />;
    case "settings":
      return <Settings show_mobile_ui={show_mobile_ui} setShowMobileSidebarAndList={setShowMobileSidebarAndList} logAction={logAction} />;
    case "empty_view":
    default:
      return <EmptyView />;
  }
};

export default MainContent;


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserContextProvider } from "../context/UserContext";
import { SnippetsContextProvider } from "../context/SnippetsContext";

// UI
import Sidebar from "../components/Sidebar";
import SnippetList from "../components/snippet/SnippetList";
import MainContent from "../components/MainContent";

function Dashboard({ subsection, setSection, logAction, match }) {
  const [ showMobileSidebarAndList, setShowMobileSidebarAndList ] = useState(false);

  // Set the current section
  useEffect(() => setSection("dashboard"), [setSection]);

  const showSnippetList = subsection !== "settings";
  const params = useParams();
  
  return (
    <UserContextProvider>
      <SnippetsContextProvider>

        <Sidebar subsection={subsection} show_mobile_ui={showMobileSidebarAndList} setShowMobileSidebarAndList={setShowMobileSidebarAndList} logAction={logAction} />
        {showSnippetList ? <SnippetList snippet_id={params.snippet_id} show_mobile_ui={showMobileSidebarAndList} setShowMobileSidebarAndList={setShowMobileSidebarAndList} logAction={logAction} /> : null}
        <MainContent subsection={subsection} show_mobile_ui={showMobileSidebarAndList} setShowMobileSidebarAndList={setShowMobileSidebarAndList} logAction={logAction} />
          
      </SnippetsContextProvider>
    </UserContextProvider>
  );
}

export default Dashboard;

import React from "react";
import { withRouter } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
    </div>
  );
};

export default PageNotFound;


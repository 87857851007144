import React, { useContext, useState, useRef, useEffect } from "react";
import http, { getErrorMessage } from "../../../utility/http";
import checkForm from "../../../utility/validator";

// Contexts
import { UserContext } from "../../../context/UserContext";

const UpdateCardForm = ({ setShowUpdateCardForm, logAction }) => {
  // Get user context
  const userContext = useContext(UserContext);
  const { user, setUser } = userContext;

  const [card, setCard] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const successTimeout = useRef(false);
  const cardNumberInput = useRef();
  const [years, setYears] = useState([]);

  // Stripe setup
  window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUB_KEY);

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    cardNumberInput.current.focus();

    // Setup year option dropdown
    const currentYear = new Date().getFullYear();
    const tempYears = [];

    for (let i = 0; i < 21; i++) {
      tempYears.push(currentYear + i);
    }

    setYears(tempYears);

    return () => {
      clearTimeout(successTimeout.current);
    };
  }, []);

  function handleInputChange({ target }) {
    const copyOfCardObject = { ...card, [target.name]: target.value };
    setCard(copyOfCardObject);
  }

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function submit(event) {
    event.preventDefault();
    setError(null);

    if (checkForm(event.target)) {
      document.activeElement.blur();
      setLoading(true);

      const cardUpdateData = {
        user_id: user.id,
        user_email: user.email,
        stripe_token: "",
        cc_id: "",
        last_four_of_cc: "",
      };

      window.Stripe.createToken(event.target, (resp, data) => {
        if (data.error) {
          setError(data.error.message);
          setLoading(false);
        } else {
          cardUpdateData.stripe_token = data.id;
          cardUpdateData.cc_id = data.card.id;
          cardUpdateData.last_four_of_cc = data.card.last4;

          http
            .put("/user/update-card/", cardUpdateData)
            .then((resp) => {
              console.log(resp);
              if (resp.data.success === false) {
                setError(resp.data.message);
              } else {
                const copyOfUser = { ...user };
                copyOfUser.subscription.last_four_of_cc =
                cardUpdateData.last_four_of_cc;
                setUser(copyOfUser);

                setSuccess(true);
                //logAction("user", "card_update");
                successTimeout.current = setTimeout(
                  () => setShowUpdateCardForm(false),
                  5000
                );
              }

              setLoading(false);
            })
            .catch((error) => {
              setError(getErrorMessage(error));
            });
        }
      });
    }
  }

  return (
    <div>
      <div className="narrow-inner-content">
        <form
          name="update_card_form"
          onSubmit={submit}
          className={`max-width-form off-center-alt ${
            loading ? "loading" : ""
          } ${success ? "hidden" : ""}`}
          noValidate
        >
          <div className="col-100 half-padding">
            <h3>Update your payment information</h3>
            <label htmlFor="field_cc_number_update" className="label">
              New Card Number
            </label>
            <input
              name="number"
              onChange={handleInputChange}
              defaultValue={card.number}
              ref={cardNumberInput}
              type="text"
              id="field_cc_number_update"
              minLength="10"
              size="20"
              maxLength="20"
              data-stripe="number"
              className="field"
              required
              data-error-text="Please enter a credit card number"
            />
          </div>

          <div className="col-65 tablet-65 mobile-100 half-padding">
            <label htmlFor="field_exp_month_update" className="label">
              Expiration
            </label>

            <div className="select-wrap short-field first-short-field">
              <select
                name="exp_month"
                onChange={handleInputChange}
                defaultValue={card.exp_month}
                id="field_exp_month_update"
                data-stripe="exp-month"
                className="field exp_month"
                required
                data-error-text=""
              >
                <option value="">MM</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>

            <div className="select-wrap short-field">
              <select
                name="exp_year"
                onChange={handleInputChange}
                value={card.exp_year}
                id="field_exp_year_update"
                data-stripe="exp-year"
                placeholder="YYYY"
                className="field exp_year"
                required
                data-error-text=""
              >
                <option value="">YYYY</option>
                {years.map((year, idx) => (
                  <option value={year} key={idx}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-35 tablet-35 mobile-100 half-padding no-left-padding">
            <label htmlFor="field_cvc_update" className="label">
              Security Code
            </label>
            <input
              name="cvc"
              onChange={handleInputChange}
              defaultValue={card.cvc}
              type="text"
              id="field_cvc_update"
              minLength="3"
              size="4"
              maxLength="4"
              data-stripe="cvc"
              className="field short-field short-field-full-until-mobile"
              required
              data-error-text=""
            />
          </div>

          <div className="col-100 half-padding">
            {formError()}
            <input
              type="submit"
              name="submit"
              value={success ? "Saved!" : "Update Card"}
              className={`btn lg push-submit small-mobile-full ${
                success ? "success" : ""
              }`}
            />
            <button
              className="inline-alt-btn-link"
              onClick={() => setShowUpdateCardForm(false)}
            >
              Cancel
            </button>
          </div>
        </form>

        {success ? (
          <div className="col-100 half-padding">
            <h3>New payment information saved!</h3>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdateCardForm


import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// Images
import avatar from "../img/avatar.svg";

const AccountAvatarBtn = ({ user }) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

    const handleClick = (e) => {
      // Close the account context menu when clicking anywhere on the screen
      if (showAccountDropdown) {
        setShowAccountDropdown(false);
        e.stopPropagation();
      } else {
        setShowAccountDropdown(true);
      }
      e.preventDefault();
    };
   

  // Window event handlers
  useEffect(() => {

    //function handleWindowClick(e) {
    //  if (showAccountDropdown && ) {
    //    setShowAccountDropdown(false);
    //  }
    //};

    function handleKeyboardShortcuts(e) {
      // Hide account context menu
      if (e.keyCode === 27 && showAccountDropdown !== false) {
        setShowAccountDropdown(false);
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);
    window.addEventListener("click", (event) => {
      if (document.querySelector("#click-div") && document.querySelector("#click-div").contains(event.target)) {
        if (document.querySelector(".object-actions").contains(event.target)) {
            setShowAccountDropdown(false);
        }
      } else {
        setShowAccountDropdown(false);
      }
    });

    // Remove event handlers on destroy
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
      //window.removeEventListener("click", handleWindowClick);
    };
  }, [showAccountDropdown, setShowAccountDropdown]);

  return (
    <div id='click-div' className={`account-avatar-area ${showAccountDropdown ? 'object-actions-shown' : ''}`}>

      <button className={`account-link ${showAccountDropdown ? 'account-link--active' : ''}`} alt="Account Menu" onClick={handleClick}>
        {user.s3_photo_location && user.s3_photo_location.small ? <span className="avatar" style={{ backgroundImage: `url(${process.env.REACT_APP_PROFILE_PICTURE_BUCKET + "/" + user.s3_photo_location.small})` }}></span> : <img src={avatar} alt="Settings" />}
      </button>

      <div className="object-actions">
        <a id="view-profile" href={`${process.env.REACT_APP_API_HOST}/user/${user.username}/`} className="object-action" target="_blank" rel="noopener noreferrer">View Profile</a>
        <Link to="/settings/profile-info" className="object-action">Account Settings</Link>
        <Link to="/logout" className="object-action">Log Out</Link>
      </div>

    </div>
  );
};

export default AccountAvatarBtn;


//https://app.snipsave.com/settings/profile-info
import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";

// Contexts
import { UserContext } from "../../context/UserContext";

// UI
import Loading from "../Loading";

function ProfileInfo(props) {

  // Get user context
  const userContext = useContext(UserContext);
  const { userLoading, user, setUser } = userContext;
  
  const [ data, setData ] = useState({});
  const [ success, setSuccess ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ emailExists, setEmailExists ] = useState(false);
  const [ processing, setProcessing ] = useState(false);
  const fileInput = useRef();
  const successTimeout = useRef(false);
  const [file, setFile] = useState(null);

  // Wait for the user data to load
  useEffect(() => {
    
    // Once the user is done loading
    if(!userLoading) {

      const userData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        website: user.website,
        twitter: user.twitter,
        profile: user.profile,
      };
      
      setData(userData);
    }
    
  }, [user, userLoading])

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
    }
  }, []);

  function handleInputChange({target}) {
    const copyOfDataObject = { ...data, [target.name]: target.value };
    setData(copyOfDataObject);
  }

  function handleFileInputChange(e) {
    const selectedFile = e.target.files[0];
    
    // Update the state with the file
    setFile(selectedFile);
    console.log(file);

  }

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function submit(event) {
    setError(null);
    
    if(checkForm(event.target)) {
      console.log('here2');
      
      document.activeElement.blur();
      setProcessing(true);

      // Perform photo upload
      if(file) {
        console.log('here3');
        const formData = new FormData();
        formData.append("file", file);
        console.log(formData.get('file'));

        http({
          method: "POST",
          url: "/user/upload-photo/",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        }).then(resp => {
        //const config = {
        //  headers: {
        //    'content-type': 'multipart/form-data',
        //  },
        //};
        //http.post("/user/upload-photo/", formData, config).then(resp => {
          console.log(resp);

          if(resp.data.success === false) {
            console.error(resp.data.message);
          } else {
            const copyOfUser = {...user};
            copyOfUser.photo = resp.data.photo;
            setUser(copyOfUser);
          }
          
        }).catch(error => {
          console.error(error);
        });
        console.log('here5');
      }
    
      http.put("/user/", data).then(resp => {
        
        if(resp.data.success === false) {

          if(resp.data.error === "email_exists") {
            setEmailExists(true);
          }
          
          setError(resp.data.message);
        } else {

          // Handle name change within the sidebar
          // if(data.first_name !== user.first_name || data.last_name !== user.last_name) {
          //   const copyOfUser = {...user};
          //   copyOfUser.first_name = data.first_name;
          //   copyOfUser.last_name = data.last_name;
          //   setUser(copyOfUser);
          // }
          
          setSuccess(true);
          fileInput.current.value = "";
          successTimeout.current = setTimeout(() => setSuccess(false), 2000);
        }

        setProcessing(false);
      }).catch((error) => {
        setError(getErrorMessage(error));
      });

      //props.logAction("user", "profile_update");
    }

    event.preventDefault();
  }

  // User data loading
  if(userLoading) {
    return <Loading />;
  }

  return (
    <div className="scrollable-area more-padding">

      <form name="account_basic_info_form" onSubmit={submit} className={`max-width-form alt-loading-position xxoff-center ${processing ? 'loading' : ''}`} noValidate>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_first_name" className="label">First Name</label>
          <input name="first_name" type="text" id="field_first_name" className="field" onChange={handleInputChange} defaultValue={data.first_name} required data-error-text="Please enter your first name" />
        </div>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_last_name" className="label">Last Name</label>
          <input name="last_name" type="text" id="field_last_name" className="field" onChange={handleInputChange} defaultValue={data.last_name} required data-error-text="Please enter your last name" />
        </div>

        <div className="col-100 half-padding">
          <label htmlFor="field_email" className="label">Email Address</label>
          <input name="email" type="email" id="field_email" className={`field ${emailExists ? 'has-error' : ''}`} onChange={handleInputChange} defaultValue={data.email} required data-error-text="Please enter your email address" />
        </div>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_website" className="label">Website</label>
          <input name="website" type="text" id="field_website" className="field" placeholder="https://" onChange={handleInputChange} defaultValue={data.website} />
        </div>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_photo" className="label">Photo</label>
          <input type="file" name="file" id="field_photo" className="field" ref={fileInput} onChange={handleFileInputChange} defaultValue={data.file} />
        </div>

        <div className="col-100 half-padding">
          <label htmlFor="field_twitter" className="label">Twitter Handle</label>

          <div className="has-physical-placeholder">
            <span className="physical-placeholder">@</span>
            <input name="twitter" type="text" id="field_twitter" placeholder="username" className="field" onChange={handleInputChange} defaultValue={data.twitter} />
          </div>
        </div>

        <div className="col-100 half-padding">
          <label htmlFor="field_profile" className="label">Profile</label>
          <textarea name="profile" id="field_profile" className="field short" onChange={handleInputChange} defaultValue={data.profile}></textarea>
        </div>

        <div className="col-100 half-padding centered">
          {formError()}
          <input type="submit" name="submit" value={success ? 'Saved!' : 'Save Changes'} className={`btn lg push-submit small-mobile-full ${success ? 'success' : ''}`} />
        </div>

      </form>

    </div>
  );
}

export default ProfileInfo;

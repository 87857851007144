import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

// UI
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

const ConfirmEmail = ({ setSection }) => {
  useEffect(() => {
    setSection("confirmemail");
  }, [setSection]);

  // Redirect logged-in users to the main app
  // if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
  //   return <Redirect to="/" />;
  // }

  console.log("pages/ConfirmEmail.js");

  return (
    <div>
      <Header section="signup" />
      <Banner title="Confirm Your Email" />

      <section id="confirmemail" className="signup-form-section">
        <div className="centered">
          <p>You will receive an email with a link to confirm your email!</p>
          <br />
          <p>
            In the meantime, view our NEW{" "}
            <a href="https://github.com/SnipSaveCLI/SnipSaveCLI" style={{ color: "blue" }}>
              Command Line interface!
            </a>
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ConfirmEmail;


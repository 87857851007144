import React, { useState, useEffect } from "react";
import { useParams, Navigate, Link, useNavigate } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Header from "../components/Header";
import Banner from "../components/Banner";

const ChangePassword = ({ setSection, logAction }) => {
  const { email, fpk, case: passwordCase } = useParams();
  const navigate = useNavigate();

  const [bannerTitle, setBannerTitle] = useState("Create a new password");
  const [data, setData] = useState({
    email,
    fpk,
    password: "",
    repeat_password: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setSection("login");

    if (passwordCase && passwordCase === "password-update-required") {
      setBannerTitle("Update your password");
    }
  }, [setSection, passwordCase]);

  const handleInputChange = ({ target }) => {
    setData((prevData) => ({ ...prevData, [target.name]: target.value }));
  };

  const formError = () => {
    return error ? <div className="form-error">{error}</div> : null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (checkForm(event.target)) {
      setLoading(true);

      if (data.password !== data.repeat_password) {
        setTimeout(() => {
          setLoading(false);
          setError("These two passwords do not match");
        }, 100);
      } else {
        try {
          const resp = await http.put("/user/change-password/", data);
          setLoading(false);

          if (resp.data.success === false) {
            setError(resp.data.message);
          } else {
            localStorage.setItem("access_token", resp.data.access_token);
            localStorage.setItem("refresh_token", resp.data.refresh_token);
            localStorage.setItem("username", resp.data.username);
            logAction("user", "password_update");
            //history.push("/");
            navigate("/");
          }
        } catch (err) {
          setLoading(false);
          setError(getErrorMessage(err));
        }
      }
    }
  };

  // Redirect logged-in users to the main app
  if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Header section="change_password" />
      <Banner title={bannerTitle} />

      <section className="standard-content">
        <div className="inner-column very-narrow half-padding">
          <form name="change_password_form" onSubmit={handleSubmit} noValidate className={`${loading ? 'loading' : ''}`}>
            <div className="col-100 half-padding">
              <label htmlFor="field_password" className="label">New Password</label>
              <input name="password" type="password" id="field_password" className="field" onChange={handleInputChange} value={data.password} required data-error-text="Please create a new password" />
            </div>

            <div className="col-100 half-padding">
              <label htmlFor="field_repeat_password" className="label">Repeat New Password</label>
              <input name="repeat_password" type="password" id="field_repeat_password" className="field" onChange={handleInputChange} value={data.repeat_password} required data-error-text="Please repeat your new password" />
            </div>

            <div className="col-100 half-padding centered">
              {formError()}
              <input type="submit" name="submit" value="Save Password" className="btn lg push-submit small-mobile-full" />
              <div>
                <Link to="/login" className="subtle-link">Log In</Link>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;


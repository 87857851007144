import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/logo.svg";

const Header = () => {
  const location = useLocation();

  const renderNav = () => {
    if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
      if (location.pathname !== "/") {
        return (
          <div className="buttons">
            <Link to="/" className="btn">
              Dashboard
            </Link>
          </div>
        );
      }
    } else {
      return (
        <div className="buttons">
          {location.pathname !== "/signup" ? (
            <Link to="/signup" className={`btn ${location.pathname !== "/login" ? "hide-small-mobile" : ""}`}>
              Sign Up
            </Link>
          ) : null}
          {location.pathname !== "/login" ? (
            <Link to="/login" className="btn btn secondary">
              Log In
            </Link>
          ) : null}
        </div>
      );
    }

    return null;
  };

  return (
    <header id="header">
      <div className="inner-column">
        <a href={process.env.REACT_APP_API_HOST} className="logo">
          <img src={logo} alt="SnipSave" />
        </a>

        {renderNav()}
      </div>
    </header>
  );
};

export default Header;


import React from "react";
import { Link, useLocation } from "react-router-dom";

const EmptyView = () => {
  const queryParams = useLocation().search;

  return (
    <section className="main nothing-selected">
      <div className="nothing-selected-box">
        <h2>Welcome to your dashboard!</h2>
        <Link to={`/snippet/new${queryParams}`} className="btn lg">
          Create New Snippet
        </Link>
      </div>
    </section>
  );
};

export default EmptyView;


import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../utility/http";

// Contexts
import { UserContext } from "../../../context/UserContext";
import { SnippetsContext } from "../../../context/SnippetsContext";

function Delete({ show, setShow, snippetID, history, location, logAction }) {
  const deleteButton = useRef();
  const navigate = useNavigate();
  //const queryParams = location.search;

  // Get user context
  const userContext = useContext(UserContext);
  const { setReloadUser } = userContext;

  // Get snippets context
  const snippetsContext = useContext(SnippetsContext);
  const { setSnippets, snippets } = snippetsContext;

  useEffect(() => {
    if (show) {
      deleteButton.current.focus();
    }
  }, [show, deleteButton]);

  const deleteSnippet = async () => {
    try {
      await http.delete(`/snippet/${snippetID}/`);

      // Remove the deleted snippet from the list
      const copyOfSnippets = snippets.filter((snippet) => snippet.id !== snippetID);
      setSnippets(copyOfSnippets);

      // Reload the user data so all sidebar snippet counts update
      setReloadUser(true);
      //logAction("snippet", "delete");

      // Go back to the empty dashboard view
      //history.push(`/${queryParams}`);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  if (show) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-inner">
            <p>Are you sure you want to delete this snippet?</p>
            <div className="dual-buttons">
              <button
                className="btn warning no-min-width first-btn delete-snippet-btn"
                onClick={deleteSnippet}
                ref={deleteButton}
              >
                Delete
              </button>
              <button
                className="btn bordered dark-border no-min-width second-btn"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default Delete;

import React, { useEffect, useRef } from "react";


function Embed({ show, setShow, snippetID }) {
  const embedScript = useRef();

  useEffect(() => {
    if (show) {
      embedScript.current.select();
    }
  }, [show, embedScript]);

  return show ? (
    <div className="modal-overlay">
      <div className="modal wide snippet-embed-modal">
        <div className="modal-inner">
          <label htmlFor="embed_script" className="label">
            Embed this snippet in any website using the following code:
          </label>
          <input
            type="text"
            id="embed_script"
            name="embed_script"
            ref={embedScript}
            value={`<script src="${process.env.REACT_APP_API_HOST}/embed/${snippetID}.js"></script>`}
            readOnly
            className="field full"
          />
          <div className="centered">
            <button className="btn done-embed-snippet-btn" onClick={() => setShow(false)}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Embed;

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";

const ChangePassword = (props) => {
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const successTimeout = useRef(false);

  const form = useRef();
  const currentPasswordInput = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
    };
  }, []);

  const handleInputChange = ({ target }) => {
    const copyOfDataObject = { ...data, [target.name]: target.value };
    setData(copyOfDataObject);
  };

  const formError = () => {
    return error ? <div className="form-error">{error}</div> : null;
  };

  const submit = (event) => {
    setError(null);
    setCurrentPasswordError(false);

    if (data.password !== data.repeat_password) {
      setError("These two passwords do not match");
    } else if (checkForm(event.target)) {
      document.activeElement.blur();
      setProcessing(true);

      http
        .put("/user/update-password/", data)
        .then((resp) => {
          console.log(resp);
          if (resp.data.success === false) {
            setError(resp.data.message);

            if (resp.data.response_type === "wrong password") {
              currentPasswordInput.current.focus();
              setCurrentPasswordError(true);
            }
          } else {
            console.log("juice0");
            form.current.reset();
            console.log("juice1");
            setSuccess(true);
            console.log("juice2");
            successTimeout.current = setTimeout(() => setSuccess(false), 2000);
            console.log("juice3");
            //props.logAction("user", "password_update");
          }

          setProcessing(false);
        })
        .catch((error) => {
          setError(getErrorMessage(error));
        });
    }

    event.preventDefault();
  };

  return (
    <div className="scrollable-area more-padding">
      <form
        name="change_password_form"
        onSubmit={submit}
        ref={form}
        className={`max-width-form alt-loading-position ${processing ? "loading" : ""}`}
        noValidate
        autoComplete="off"
      >
        <div className="col-100 half-padding">
          <label htmlFor="field_current_password" className="label">
            Current Password
          </label>
          <input
            name="current_password"
            type="password"
            id="field_current_password"
            className={`field ${currentPasswordError ? "has-error" : ""} `}
            ref={currentPasswordInput}
            onChange={handleInputChange}
            required
            data-error-text="Please enter your current password"
            autoComplete="new-password"
          />
        </div>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_password" className="label">
            New Password
          </label>
          <input
            name="password"
            type="password"
            id="field_password"
            className="field"
            onChange={handleInputChange}
            required
            data-error-text="Please create a new password"
          />
        </div>

        <div className="col-50 small-mobile-100 half-padding">
          <label htmlFor="field_repeat_password" className="label">
            Repeat New Password
          </label>
          <input
            name="repeat_password"
            type="password"
            id="field_repeat_password"
            className="field"
            onChange={handleInputChange}
            required
            data-error-text="Please repeat your new password"
          />
        </div>

        <div className="col-100 half-padding centered">
          {formError()}
          <input
            type="submit"
            name="submit"
            value={success ? "Saved!" : "Save New Password"}
            className={`btn lg push-submit small-mobile-full ${success ? "success" : ""}`}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;


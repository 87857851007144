import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter, Link } from "react-router-dom";

// Context
import { UserContext } from "../../context/UserContext";
import { SnippetsContext } from "../../context/SnippetsContext";

// UI
import Loading from "../../components/Loading";

// Images
import searchIcon from "../../img/search.svg";
import xIcon from "../../img/icon-x.svg";

const SnippetList = ({ snippet_id, show_mobile_ui, setShowMobileSidebarAndList, location }) => {
  const listView = useRef();
  const searchField = useRef(null);
  const cancelSearchBtn = useRef();

  // Get user context
  const { user, userLoading } = useContext(UserContext);

  // Get snippets context
  const { snippetsLoading, snippets, searchSnippets, rerenderSnippetList, setRerenderSnippetList } = useContext(SnippetsContext);

  const [selectedSnippet, setSelectedSnippet] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showMobileListColumn, setShowMobileListColumn] = useState(false);

  // Set active state on selected snippet
  useEffect(() => {
    setSelectedSnippet(snippet_id);
  }, [snippet_id]);

  useEffect(() => {
    setShowMobileListColumn(show_mobile_ui);
  }, [show_mobile_ui]);

  // Clear the search text when a new snippet is added
  useEffect(() => {
    if (rerenderSnippetList) {

      // Clear the search text because we want to definitely see the new snippet
      if (rerenderSnippetList === "snippet_added") {
        cancelSearchBtn.current.click();
        searchField.current.blur();
      }

      // Re-trigger the snippet search because we want to keep the filtered view
      if (rerenderSnippetList === "snippet_edited") {
        if (searchText) {
          searchSnippets(searchText);
        }
      }

      setRerenderSnippetList(false);
    }
  }, [searchText, searchSnippets, rerenderSnippetList, setRerenderSnippetList]);

  function handleSearchText(text) {

    if (text === "") {
      searchField.current.focus();
    } else {
      setSearchText(text);
      searchSnippets(text);
    }
  };

  const handleDragStart = (e, snippet) => {
    const draggedNode = e.currentTarget.cloneNode(true);
    draggedNode.classList.add("being-dragged");
    document.body.appendChild(draggedNode);

    e.dataTransfer.setDragImage(draggedNode, 0, 0);
    e.dataTransfer.setData("snippet_id", snippet.id);
  };

  const handleDragEnd = (e, snippet) => {
    const draggedNode = document.querySelector(`#${e.currentTarget.id}.being-dragged`);

    if (draggedNode) {
      draggedNode.remove();
    }
  };

  // When snippets are reloaded, we need to clear the search text
  useEffect(() => {
    if (snippetsLoading && searchText) {
      setSearchText("");
    }
  }, [searchText, snippetsLoading]);

  const NoResults = () => {
    return (
      <div className="list-view-empty-msg">
        No snippets found
      </div>
    );
  };

  const getFolderName = (folder_id) => {
    if (!userLoading && folder_id) {
      const folder = user.folders.find(folder => folder.id === folder_id);

      if (folder) {
        return folder.name;
      }
    }

    return "";
  };

  return (
    <section className={`list-column ${showMobileListColumn ? 'show-mobile' : ''}`}>
      <div className="list-column-inner">
        
        <div className={`search-form-wrapper ${searchText ? 'has-search-text' : ''}`}>
          <img src={searchIcon} alt="Search" className="search-icon" />
          <input name="search" type="search" id="field_title" className="field small" placeholder="Search..." ref={searchField} value={searchText} onChange={(e) => handleSearchText(e.target.value)} defaultValue={undefined} autoComplete="off"/>
          <button className="cancel-search-button" ref={cancelSearchBtn} onClick={() => handleSearchText("")}>
            <img src={xIcon} alt="Cancel search" />
          </button>
        </div>

        <Loading loading={snippetsLoading} />

        {snippets.length === 0 && !snippetsLoading ? <NoResults/> : null}

        <ul className={`list-column-list fades-in ${snippets.length === 0 || snippetsLoading ? 'fades-in--hidden' : ''}`} ref={listView}>
          {snippets.map((snippet) => (
            <li key={snippet.id} id={`snippet_${snippet.id}`} className="list-column-list-item" draggable onDragStart={(e) => handleDragStart(e, snippet)} onDragEnd={(e) => handleDragEnd(e, snippet)}>
              {/*<Link to={`/snippet/${snippet.id}${location.search}`} onClick={() => setShowMobileSidebarAndList(false)} className={`${selectedSnippet === snippet.id ? 'active' : ''}`}>*/}
              <Link to={`/snippet/${snippet.id}`} onClick={() => setShowMobileSidebarAndList(false)} className={`${selectedSnippet === snippet.id ? 'active' : ''}`}>
                <span className="title">{snippet.title}</span>
                <span className="language">{snippet.language}</span>
                <span className="folder">
                  {getFolderName(snippet.folder)}
                </span>
              </Link>
            </li>
          ))}
        </ul>

      </div>
    </section>
  );
};

export default SnippetList;


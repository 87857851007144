import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";


// Contexts
import { UserContext } from "../../context/UserContext";

// UI
import Loading from "../Loading";
import FormattedDate from "../../utility/FormattedDate";
//import Highlight from "react-highlight";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Embed from "./actions/Embed";
import Delete from "./actions/Delete";

// Images
import pencilIcon from "../../img/icon-pencil.svg";
import linkIcon from "../../img/icon-link.svg";
import embedIcon from "../../img/icon-embed.svg";
import trashIcon from "../../img/icon-trash.svg";

const SnippetView = () => {
  const { snippet_id } = useParams();
  const queryParams = useLocation().search;

  const snippetTitleBar = useRef(null);
  const [scrollableAreaHeight, setScrollableAreaHeight] = useState(null);

  const snippetContentTextarea = useRef(null);
  const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [copySuccessful, setCopySuccessful] = useState(false);
  const copySuccessTimeout = useRef(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [snippet, setSnippet] = useState({});

  // Get user context
  const userContext = useContext(UserContext);
  const { user } = userContext;

  useEffect(() => {
    setLoading(true);
    setFadeIn(false);

    http.get(`/snippet/${snippet_id}/`)
      .then(resp => {
        if (resp.data.success === false) {
          setNotFound(true);
          setLoading(false);
        } else {
          setSnippet(resp.data);
          setLoading(false);
          setNotFound(false);

          // Fade the snippet content in
          setTimeout(() => setFadeIn(true), 0);
          adjustSnippetViewHeight();
        }
      })
      .catch((err) => {
        console.error(getErrorMessage(err));
      });

  }, [snippet_id]);

  // Window event handlers
  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardShortcuts);
    window.addEventListener("resize", adjustSnippetViewHeight);

    function handleKeyboardShortcuts(e) {
      // Open embed modal
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 69) {
        setShowEmbedModal(true);
        e.preventDefault();
      }

      // Open delete modal
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 8) {
        setShowDeleteModal(true);
        e.preventDefault();
      }

      // Handle escape key
      if (e.keyCode === 27) {
        setShowDeleteModal(false);
        setShowEmbedModal(false);
      }
    }

    // Remove event handlers on destroy
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
      window.removeEventListener("resize", adjustSnippetViewHeight);
    };
  }, []);

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    return () => {
      clearTimeout(copySuccessTimeout.current);
    };
  }, []);

  function adjustSnippetViewHeight() {
    // Adjust the snippet-area scrollable height if necessary
    if (snippetTitleBar.current && snippetTitleBar.current.clientHeight) {
      const titleBarHeight = snippetTitleBar.current.clientHeight;
      const viewportHeight = document.body.clientHeight;
      const newHeightToSet = `${viewportHeight - titleBarHeight}px`;
      setScrollableAreaHeight({ maxHeight: newHeightToSet });
    }
  }

  function copyToClipboard() {
    snippetContentTextarea.current.select();
    document.execCommand("copy");
    setCopySuccessful(true);
    copySuccessTimeout.current = setTimeout(() => setCopySuccessful(false), 2000);
  }

  // Loading Snippet
  if (loading) {
    return <Loading />;
  }

  // Snippet Not Found
  if (notFound) {
    return (
      <section className="main not-found">
        <div className="not-found-box">
          <h3 className="centered">Not Found</h3>
          <p className="centered">Sorry, this code snippet doesn't seem to exist.</p>
        </div>
      </section>
    );
  }

  const PrivacyTag = () => {
    if (snippet.privacy === "private") {
      return <span className="label-tag private">Private</span>;
    } else if (snippet.privacy === "unlisted") {
      return <span className="label-tag unlisted">Unlisted</span>;
    }
    return null;
  };

  const SnippetTags = () => {
    if (snippet.tags.length) {
      return snippet.tags.map((tag, idx) => (
        <Link to={`/?tag=${tag}`} className="tag" key={`key_${idx}`}>
          {tag}
        </Link>
      ));
    }
    return null;
  };

  const ViewSnippetLink = () => {
    if (snippet.privacy !== "private") {
      return (
        <a
          href={`${process.env.REACT_APP_API_HOST}/user/${user.username}/snippet/${snippet.id}/`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-snippet-action btn-link-snippet"
        >
          <img src={linkIcon} alt="Link" />
        </a>
      );
    }
    return null;
  };

  return (
    <div className={`fades-in ${!fadeIn ? 'fades-in--hidden' : ''}`}>
      <Delete show={showDeleteModal} setShow={setShowDeleteModal} snippetID={snippet_id} />
      <Embed show={showEmbedModal} setShow={setShowEmbedModal} snippetID={snippet_id} />

      <section className="main">
        <div className="snippet">
          <div ref={snippetTitleBar} className="main-title-bar minimal">
            <h3 className={snippet.privacy !== 'public' ? 'less-wide' : ''}>{snippet.title}</h3>
            <PrivacyTag />
            <div className="controls">
              <ViewSnippetLink />
              <Link to={`/snippet/${snippet.id}/edit${queryParams}`} className="btn-snippet-action btn-edit-snippet">
                <img src={pencilIcon} alt="Edit" />
              </Link>
              <button className="btn-snippet-action btn-embed-snippet" onClick={() => setShowEmbedModal(true)}>
                <img src={embedIcon} alt="Embed" />
              </button>
              <button className="btn-snippet-action btn-delete-snippet" onClick={() => setShowDeleteModal(true)}>
                <img src={trashIcon} alt="Delete" />
              </button>
            </div>
          </div>

          <div className="scrollable-area" style={scrollableAreaHeight}>
            <div className="snippet-meta-bar">
              <span className="date">
                Created on <FormattedDate>{snippet.date_created}</FormattedDate>
              </span>
              <div className="tags">
                <SnippetTags />
              </div>
            </div>

            <div className="snippet-content">
              <button className="btn dark-border bordered sm copy-btn" onClick={copyToClipboard}>
                {copySuccessful ? 'Copied!' : 'Copy'}
              </button>
              <SyntaxHighlighter language={snippet.language} style={github}>{snippet.content}</SyntaxHighlighter>
              <textarea name="snippet_content_for_copying" className="snippet_content_for_copying" ref={snippetContentTextarea} value={snippet.content} readOnly></textarea>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SnippetView;


import React from "react";
import loading from "../img/loading.svg";

const Loading = ({ loading }) => {
  return loading !== false ? (
    <div className="loading-spinner">
      <img src={loading} alt="Loading" />
    </div>
  ) : null;
};

export default Loading;


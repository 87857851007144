import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import http from "../../../utility/http";

// Contexts
import { UserContext } from "../../../context/UserContext";

// UI
import UpdateCardForm from "./UpdateCardForm";
import Benefits from "./Benefits";

function SubscriptionDetails({ history, logAction }) {
  // Get user context
  const userContext = useContext(UserContext);
  const { user, setUser } = userContext;

  const [showUpdateCardForm, setShowUpdateCardForm] = useState(false);
  const [cancellationProcessing, setCancellationProcessing] = useState(false);

  const cancelSubscription = async () => {
    if (window.confirm("WARNING: Cancelling your SnipSave Pro membership will make all of your private and unlisted snippets public. Are you sure you want to continue?")) {
      setCancellationProcessing(true);

      try {
        const resp = await http.put("/user/cancel-subscription/", {});

        if (resp.data.success) {
          // Update the auth tokens
          localStorage.setItem("access_token", resp.data.access_token);
          localStorage.setItem("refresh_token", resp.data.refresh_token);

          // Update the user object
          const copyOfUser = { ...user };
          copyOfUser.pro = false;
          delete copyOfUser.subscription;
          setUser(copyOfUser);
          logAction("user", "subscription_cancel");

          // Go to the goodbye/survey page
          history.push("/settings/subscription-cancelled");
        } else {
          alert(resp.data.message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCancellationProcessing(false);
      }
    }
  };

  return (
    <div className="max-width-700">

      <div className="col-100 half-padding">
        <h3>Your SnipSave Pro Membership</h3>
        <p>You are subscribed to SnipSave Pro for <strong className="almost-black">${user.subscription.price}/month</strong> with a credit card ending in <strong className="almost-black">{user.subscription.last_four_of_cc}</strong>.</p>
      </div>

      <div>
        <div className="col-100 half-padding">
          <div className="hr less-top"></div>
          {!showUpdateCardForm ? <h3>Need to update your payment information?</h3> : null}
          {!showUpdateCardForm ? <p><button className="btn small" onClick={() => setShowUpdateCardForm(true)}>Update payment info</button></p> : null}
        </div>

        {showUpdateCardForm ? <UpdateCardForm setShowUpdateCardForm={setShowUpdateCardForm} logAction={logAction} /> : null}
      </div>

      <div className="col-100 half-padding">
        <div className="hr"></div>

        <Benefits />

        <div className="hr no-top"></div>

        <p className={`light-text pb--md ${cancellationProcessing ? 'paragraph-loading' : ''}`}>
          Do you need to <button className="simple-link" onClick={cancelSubscription}>cancel your subscription</button>?
        </p>
      </div>

    </div>
  );
}

export default SubscriptionDetails;


import React, { useEffect } from "react";
import { withRouter, useNavigate } from "react-router-dom";
import http from "../utility/http";

const Logout = ({ setSection, logAction, history }) => {
  const navigation = useNavigate();
  useEffect(() => {
    const handleLogout = async () => {
      setSection("logout");
      logAction("user", "logout");

      try {
        await http.post("/user/logout/");
        clearStorageAndRedirect();
      } catch (error) {
        clearStorageAndRedirect();
      }
    };

    handleLogout();
  }, [setSection, logAction, history]);

  const clearStorageAndRedirect = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    navigation("/login");
    // window.location = process.env.REACT_APP_API_HOST;
  };

  return null;
};

export default Logout;


import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";
import { UserContext } from "../../context/UserContext";
import { SnippetsContext } from "../../context/SnippetsContext";
import Loading from "../Loading";
import Select from "react-select";
import Creatable from "react-select/creatable";
import languageOptions from "../LanguageOptions";
import privacyOptions from "../PrivacyOptions";
import AceEditor from "react-ace";
import "brace/theme/github";
import "../AceModes";
import infoIcon from "../../img/icon-info.svg";

function SnippetNew() {
  const { userLoading, user, setReloadUser } = useContext(UserContext);
  const snippetsContext = useContext(SnippetsContext);
  const { addSnippet, getRedirectPath } = snippetsContext;
  const location = useLocation();
  const queryParams = location.search;

  const initialData = {
    title: "",
    language: "text",
    content: "",
    folder: "",
    tags: [],
    privacy: "public",
  };

  const [data, setData] = useState(initialData);
  const [folderOptions, setFolderOptions] = useState([{ label: "None", value: "" }]);
  const [selectedFolder, setSelectedFolder] = useState({ label: "None", value: "" });
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({ label: "Text", value: "text" });
  const [selectedPrivacy, setSelectedPrivacy] = useState(privacyOptions[0]);
  const [error, setError] = useState(null);
  const [snippetEmpty, setSnippetEmpty] = useState(false);
  const [processing, setProcessing] = useState(false);
  const saveButton = useRef();
  const navigate = useNavigate();


  useEffect(() => {
    if (!userLoading) {
      const folderOptionList = [{ label: "None", value: "" }, ...user.folders.map(folder => ({ label: folder.name, value: folder.id }))];
      setFolderOptions(folderOptionList);
      const loadedTagOptions = Object.keys(user.tags).map(tag => ({ label: tag, value: tag }));
      setTagOptions(loadedTagOptions);
    }
  }, [user, userLoading]);

  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        saveButton.current.click();
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);

    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
    };
  }, [saveButton]);

  const handleInputChange = ({ target }) => setData(prevData => ({ ...prevData, [target.name]: target.value }));

  const handleEditorChange = (newCode) => setData(prevData => ({ ...prevData, content: newCode }));

  const handleSelectChange = (key, selectedOption) => {
    if (key === "language") {
      setSelectedLanguage(selectedOption);
    } else if (key === "folder") {
      setSelectedFolder(selectedOption);
    } else if (key === "privacy") {
      setSelectedPrivacy(selectedOption);
    }
    setData(prevData => ({ ...prevData, [key]: selectedOption.value }));
  };

  const addNewTagOption = (tag) => {
    const newTagObject = { label: tag, value: tag };
    setSelectedTags(prevTags => [...prevTags, newTagObject]);
    setTagOptions(prevOptions => [...prevOptions, newTagObject]);
    setData(prevData => ({ ...prevData, tags: [...prevData.tags, tag] }));
  };

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
    setData(prevData => ({ ...prevData, tags: selectedOptions ? selectedOptions.map(tag => tag.value) : [] }));
  };

  const formError = () => (error ? <div className="form-error">{error}</div> : null);

  const Submit = (event) => {
    setError(null);

    if (!data.content) {
      setSnippetEmpty(true);
    } else if (checkForm(event.target)) {
      setProcessing(true);

      http.post("/snippet/", data)
        .then(resp => {
          if (resp.data.success === false) {
            setProcessing(false);
            setError(resp.data.message);
          } else {
            addSnippet(resp.data);
            // Assuming `props.logAction` is a function passed as a prop
            // You may replace this with the appropriate context action if needed
            //props.logAction("snippet", "add");
            setReloadUser(true);
            data.id = resp.data.id;
            navigate(getRedirectPath(data));
          }
        })
        .catch(error => {
          setError(getErrorMessage(error));
        });
    }

    event.preventDefault();
  };

  if (userLoading) {
    return <Loading />;
  }

  return (
    <section className="main light-bg no-padding">
      <div className="main-section">
        <div className="main-title-bar">
          <h3>Add New Snippet</h3>
        </div>

        <div className="main-section-content padded-bottom">
          <div className="scrollable-area more-padding">
            <form
              name="new_snippet_form"
              onSubmit={Submit}
              noValidate
              className={`alt-loading-position ${processing ? 'loading' : ''}`}
            >
              <div className="col-70 medium-mobile-100 half-padding">
                <label htmlFor="field_title" className="label">
                  Title
                </label>
                <input
                  autoComplete="off"
                  name="title"
                  type="text"
                  id="field_title"
                  className="field"
                  autoFocus
                  onChange={handleInputChange}
                  defaultValue={data.title}
                  required
                  data-error-text="Please enter a snippet title"
                />
              </div>

              <div className="col-30 medium-mobile-100 half-padding select-language-wrapper">
                <label htmlFor="field_language" className="label">
                  Language
                </label>
                <Select
                  id="field_language"
                  value={selectedLanguage}
                  onChange={(selectedOption) => handleSelectChange("language", selectedOption)}
                  options={languageOptions}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="col-100 half-padding">
                <label htmlFor="field_content" className="label">
                  Code
                </label>
                <AceEditor
                  className={snippetEmpty ? 'has-error' : ''}
                  value={data.content}
                  mode={data.language}
                  theme="github"
                  width="100%"
                  height="340px"
                  fontSize={13}
                  showPrintMargin={false}
                  highlightActiveLine={false}
                  onChange={handleEditorChange}
                  name="editor"
                  editorProps={{ $blockScrolling: true }}
                />
                {snippetEmpty ? <label className="control-label has-error validationMessage">Please enter some code</label> : null}
              </div>

              <div className="col-70 medium-mobile-100 half-padding select-folder-wrapper">
                <label htmlFor="field_folder" className="label">
                  Folder
                </label>
                <Select
                  id="field_folder"
                  value={selectedFolder}
                  onChange={(selectedOption) => handleSelectChange("folder", selectedOption)}
                  options={folderOptions}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="col-30 medium-mobile-100 half-padding select-privacy-wrapper">
                <label htmlFor="field_privacy" className="label">
                  Privacy
                  <Link
                    to="/settings/manage-subscription"
                    className={`info-popup ${user.pro ? 'info-popup--hidden' : ''}`}
                  >
                    <img src={infoIcon} alt="Info" />
                    <span className="text">
                      Upgrade to Pro for <strong>Private</strong> & <strong>Unlisted</strong>{" "}
                      <span style={{ top: "-1px" }}>snippets!</span>
                    </span>
                  </Link>
                </label>
                <Select
                  id="field_privacy"
                  value={selectedPrivacy}
                  onChange={(selectedOption) => handleSelectChange("privacy", selectedOption)}
                  options={privacyOptions}
                  isDisabled={!user.pro}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="col-100 medium-mobile-100 half-padding select-tags-wrapper">
                <label htmlFor="field_tags" className="label">
                  Tags
                </label>
                <Creatable
                  id="field_tags"
                  value={selectedTags}
                  onCreateOption={addNewTagOption}
                  onChange={handleTagsChange}
                  options={tagOptions}
                  noOptionsMessage={() => 'No tags found'}
                  isMulti
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="col-100 half-padding centered dual-buttons">
                {formError()}
                <input
                  type="submit"
                  name="submit"
                  value="Save Snippet"
                  className="btn lg push-submit"
                  ref={saveButton}
                />
                <Link to={`/${queryParams}`} id="cancel_add_snippet_btn" className="btn bordered dark-border lg push-submit">
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SnippetNew;


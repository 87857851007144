import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// Contexts
import { UserContext } from "../context/UserContext";
import { SnippetsContext } from "../context/SnippetsContext";

// UI
import Loading from "../components/Loading";
import AccountAvatarBtn from "./AccountAvatarBtn";
import NewFolderForm from "./NewFolderForm";
import Folder from "./Folder";

// Images
import logo from "../img/logo.svg";
import addIcon from "../img/icon-add.svg";
import arrowLeft from "../img/icon-arrow-left.svg";
import arrowRight from "../img/icon-arrow-right.svg";

function Sidebar({ subsection, show_mobile_ui, setShowMobileSidebarAndList, logAction }) {
  const [showAllFolders, setShowAllFolders] = useState(false);
  const [showAllLanguages, setShowAllLanguages] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [firstSnippetLoad, setFirstSnippetLoad] = useState(true);
  const [showNewFolderForm, setShowNewFolderForm] = useState(false);
  const queryParams = useLocation().search;

  // Get user context
  const userContext = useContext(UserContext);
  const { userLoading, user } = userContext;

  // Get snippets context
  const snippetsContext = useContext(SnippetsContext);
  const { snippetsLoading } = snippetsContext;

  useEffect(() => {
    if (!snippetsLoading) {
      setFirstSnippetLoad(false);
    }
  }, [snippetsLoading]);

  useEffect(() => {
    setShowMobileSidebar(show_mobile_ui);
  }, [show_mobile_ui]);

  // Show the sidebar as loading if the user data is still loading or if
  // the snippets are still loading for the first time
  if (userLoading || (firstSnippetLoad && snippetsLoading)) {
    return (
      <section className="sidebar">
        <Loading />
      </section>
    );
  }

  const ShowHideAll = ({ items, setShowAll, showAll }) => {
    if (items.length > 5) {
      return (
        <button className="toggle-show-all-items" onClick={() => setShowAll(!showAll)}>
          {!showAll ? <span>Show All &darr;</span> : null}
          {showAll ? <span>Show Less &uarr;</span> : null}
        </button>
      );
    }

    return null;
  };

  return (
    <section className={`sidebar ${showMobileSidebar ? "show-mobile" : ""}`}>
      <div className="sidebar-header">
        <Link to="/" className="logo logo--sidebar">
          <img src={logo} alt="SnipSave Logo" />
        </Link>
        <AccountAvatarBtn user={user} />
      </div>

      <span id="toggle_sidebar_btn" className="toggle-sidebar-tab" onClick={() => setShowMobileSidebarAndList(!show_mobile_ui)}>
        <img src={arrowRight} className="right-arrow" alt="Show Nav" />
        <img src={arrowLeft} className="left-arrow" alt="Hide Nav" />
      </span>

      <div className="sidebar-inner">
        <div className="column-block">
          <div className="block-content padded">
            <Link to={`/snippet/new${queryParams}`} onClick={() => setShowMobileSidebarAndList(false)} id="new_snippet_btn" className="btn full">
              New Snippet
            </Link>
          </div>

          <div className="block-content">
            <ul className="sidebar-list">
              <li className="sidebar-list-item">
                <Link to="/" className={!queryParams && subsection !== "settings" ? "active" : ""}>
                  All Snippets
                  <span className="count">{user.snippet_count}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Folders */}
        <div className="column-block">
          <div className="block-title">
            Folders
            <button className={`block-title-icon-link ${showNewFolderForm ? "rotate-45" : ""}`} onClick={() => setShowNewFolderForm(!showNewFolderForm)}>
              <img src={addIcon} className="icon" alt="Add Folder" />
            </button>
          </div>

          <div className="block-content">
            <ul className="sidebar-list">
              <NewFolderForm showForm={showNewFolderForm} setShowForm={setShowNewFolderForm} logAction={logAction} />

              {user.folders.map((folder, idx) => {
                // Only show the first 5 folders unless the user has decided to expand all
                if (!showAllFolders && idx > 4) {
                  return false;
                }

                return <Folder key={folder.id} folder={folder} logAction={logAction} />;
              })}
            </ul>

            <ShowHideAll items={user.folders} setShowAll={setShowAllFolders} showAll={showAllFolders} />
          </div>
        </div>
        {/* End: Folders */}

        {/* Languages */}
        <div className={`column-block ${!Object.keys(user.languages).length ? "hidden" : ""}`}>
          <div className="block-title">Languages</div>

          <div className="block-content">
            <ul className="sidebar-list">
              {Object.keys(user.languages).map((langKey, idx) => {
                // Only show the first 5 languages unless the user has decided to expand all
                if (!showAllLanguages && idx > 4) {
                  return false;
                }

                return (
                  <li className="sidebar-list-item" key={`lang_${idx}`}>
                    <Link to={`/?language=${user.languages[langKey].name}`} className={queryParams === `?language=${user.languages[langKey].name}` ? "active" : ""}>
                      {user.languages[langKey].name}
                      <span className="count">{user.languages[langKey].count}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>

            <ShowHideAll items={Object.keys(user.languages)} setShowAll={setShowAllLanguages} showAll={showAllLanguages} />
          </div>
        </div>
        {/* End: Languages */}

        {/* Tags */}
        <div className={`column-block ${!Object.keys(user.tags).length ? "hidden" : ""}`}>
          <div className="block-title">Tags</div>

          <div className="block-content">
            <ul className="sidebar-list">
              {Object.keys(user.tags).map((tagKey, idx) => {
                // Only show the first 5 tags unless the user has decided to expand all
                if (!showAllTags && idx > 4) {
                  return false;
                }

                return (
                  <li className="sidebar-list-item" key={`tag_${idx}`}>
                    <Link to={`/?tag=${user.tags[tagKey].name}`} className={queryParams === `?tag=${user.tags[tagKey].name}` ? "active" : ""}>
                      {user.tags[tagKey].name}
                      <span className="count">{user.tags[tagKey].count}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>

            <ShowHideAll items={Object.keys(user.tags)} setShowAll={setShowAllTags} showAll={showAllTags} />
          </div>
        </div>
        {/* End: Tags */}
      </div>
    </section>
  );
}

export default Sidebar;


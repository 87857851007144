import React, { useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Switch, Routes, Navigate, useLocation } from "react-router-dom";
//import http from "./utility/http";
import "./App.css" //import "./App.compiled.css";

// Utility
import ScrollTop from "./utility/ScrollTop";

// Pages
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import ConfirmEmail from "./pages/ConfirmEmail";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Logout from "./pages/Logout";
import PageNotFound from "./pages/PageNotFound";

// Protected Route
function ProtectedRoute({ children }) {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  //const history = useHistory();
  //const navigate = useNavigate();

  const location = useLocation();
  const redirectPath = location.pathname === "/" ? "/login" : `/login?redirect=${location.pathname}`;

  return accessToken && refreshToken ? children : <Navigate to={redirectPath} />;
}

const App = () => {
  const [section, setSection] = useState("");

  const logAction = useCallback((category, action) => {
  //  http.post("/log-action/", {
  //    category,
  //    action,
  //  });
  }, []);

  return (
    <Router>
      <ScrollTop>
        <div className="app">
          <Routes>
            {/* Signup & Login Routes */}
            <Route exact path="/confirm-email" element={<ConfirmEmail setSection={setSection} logAction={logAction} />} />
            <Route exact path="/signup" element={<Signup setSection={setSection} logAction={logAction} />} />
            <Route exact path="/login" element={<Login setSection={setSection} logAction={logAction} />} />
            <Route exact path="/forgot-password" element={<ForgotPassword setSection={setSection} logAction={logAction} />} />
            <Route exact path="/change-password/:email/:fpk" element={<ChangePassword setSection={setSection} logAction={logAction} />} />
            <Route exact path="/change-password/:email/:fpk/:case" element={<ChangePassword setSection={setSection} logAction={logAction} />} />
            <Route exact path="/logout" element={<Logout setSection={setSection} logAction={logAction} />} />

            {/* Dashboard Routes  
            <ProtectedRoute path="/">
              <Dashboard subsection="empty_view" setSection={setSection} logAction={logAction} />
            </ProtectedRoute> */}
            <Route path="/snippet/new" element={<ProtectedRoute><Dashboard subsection="snippet_new" setSection={setSection} logAction={logAction}/></ProtectedRoute>} />
            <Route path="/snippet/:snippet_id/edit" element={<ProtectedRoute><Dashboard subsection="snippet_edit" setSection={setSection} logAction={logAction}/></ProtectedRoute>} />
            <Route path="/snippet/:snippet_id" element={<ProtectedRoute><Dashboard subsection="snippet_view" setSection={setSection} logAction={logAction}/></ProtectedRoute>} />
            <Route path="/settings/:tab" element={<ProtectedRoute><Dashboard subsection="settings" setSection={setSection} logAction={logAction}/></ProtectedRoute>} />
            <Route path="/" element={<ProtectedRoute><Dashboard subsection="empty_view" setSection={setSection} logAction={logAction}/></ProtectedRoute>} />
        
            {/*Page Not Found Routes */}
            <Route path="*" element={<PageNotFound setSection={setSection} logAction={logAction} />} />
          </Routes>
        </div>
      </ScrollTop>
    </Router>
  );
};

export default App;


import React, { useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

function SignupForm() {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    marketing_notifications: true,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //const history = useHistory();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const formError = () => {
    return error ? <div className="form-error" ng-show="formError">{error}</div> : null;
  };

  const submit = (event) => {
    console.log('submitting user');
    setError("");
    if (checkForm(event.target)) {
      setLoading(true);
      http.post("/user/", data).then((resp) => {
        setLoading(false);
        if (resp.data.success === false) {
            setError("");
            setError(resp.data.message);
        } else {
          localStorage.setItem("access_token", resp.data.access_token);
          localStorage.setItem("refresh_token", resp.data.refresh_token);
          localStorage.setItem("username", resp.data.username);
          // Assuming logAction is a global function
          //logAction("user", "signup");
          navigate("/confirm-email");
        }
      }).catch((error) => {
        setLoading(false);
        setError(getErrorMessage(error));
      });
    }
    event.preventDefault();
  };

  const handleCheckboxChange = () => {
    setData((prevData) => ({ ...prevData, marketing_notifications: !prevData.marketing_notifications }));
  };

  return (
    <form name="signup_form" onSubmit={submit} noValidate className={`alt-loading-position ${loading ? 'loading' : ''}`}>
      <div className="col-100 half-padding">
        <h2 className="lg centered">Create your free account</h2>
      </div>

      <div className="col-100 half-padding">
        <label htmlFor="field_first_name" className="label">First Name</label>
        <input name="first_name" type="text" id="field_first_name" className="field" autoFocus onChange={handleInputChange} value={data.first_name} required data-error-text="Please enter your first name" />
      </div>

      <div className="col-100 half-padding">
        <label htmlFor="field_last_name" className="label">Last Name</label>
        <input name="last_name" type="text" id="field_last_name" className="field" onChange={handleInputChange} value={data.last_name} required data-error-text="Please enter your last name" />
      </div>

      <div className="col-100 half-padding">
        <label htmlFor="field_email" className="label">Email Address</label>
        <input name="email" type="email" id="field_email" className="field" onChange={handleInputChange} value={data.email} required data-error-text="Please enter your email address" />
      </div>

      <div className="col-100 half-padding">
        <label htmlFor="field_password" className="label">Password</label>
        <input name="password" type="password" id="field_password" className="field" onChange={handleInputChange} value={data.password} required data-error-text="Please create a password" />
      </div>

      <div className="col-100 small-mobile-100 half-padding">
        <input type="checkbox" id="marketing_notifications" name="marketing_notifications" checked={data.marketing_notifications} onChange={handleCheckboxChange} />
        <label htmlFor="marketing_notifications" style={{ fontSize: '12px', padding: '3px' }}>You agree to receive marketing offers about Snipsave!</label>
      </div>

      <div className="col-100 half-padding centered">
        {formError()}
        <input type="submit" name="submit" value="Get Started" className="btn lg push-submit small-mobile-full" />
      </div>
    </form>
  );
}

export default SignupForm;


const languages = [
  { label: "ABAP", value: "abap" },
  { label: "ABC", value: "abc" },
  { label: "ActionScript", value: "actionscript" },
  { label: "ADA", value: "ada" },
  { label: "Apache Conf", value: "apache_conf" },
  { label: "AsciiDoc", value: "asciidoc" },
  { label: "Assembly x86", value: "assembly_x86" },
  { label: "AutoHotKey", value: "autohotkey" },
  { label: "BatchFile", value: "batchfile" },
  { label: "Bro", value: "bro" },
  { label: "C and C", value: "c_cpp" },
  { label: "C9Search", value: "c9search" },
  { label: "Cirru", value: "cirru" },
  { label: "Clojure", value: "clojure" },
  { label: "Cobol", value: "cobol" },
  { label: "CoffeeScript", value: "coffee" },
  { label: "ColdFusion", value: "coldfusion" },
  { label: "C#", value: "csharp" },
  { label: "CSS", value: "css" },
  { label: "Curly", value: "curly" },
  { label: "D", value: "d" },
  { label: "Dart", value: "dart" },
  { label: "Diff", value: "diff" },
  { label: "Django", value: "django" },
  { label: "Dockerfile", value: "dockerfile" },
  { label: "Dot", value: "dot" },
  { label: "Drools", value: "drools" },
  // { label: "Dummy", value: "dummy" }, // No brace file for this
  // { label: "DummySyntax", value: "dummysyntax" }, // No brace file for this
  { label: "Eiffel", value: "eiffel" },
  { label: "EJS", value: "ejs" },
  { label: "Elixir", value: "elixir" },
  { label: "Elm", value: "elm" },
  { label: "Erlang", value: "erlang" },
  { label: "Forth", value: "forth" },
  { label: "Fortran", value: "fortran" },
  { label: "FreeMarker", value: "ftl" },
  { label: "Gcode", value: "gcode" },
  { label: "Gherkin", value: "gherkin" },
  { label: "Gitignore", value: "gitignore" },
  { label: "Glsl", value: "glsl" },
  { label: "Gobstones", value: "gobstones" },
  { label: "Go", value: "golang" },
  { label: "GraphQLSchema", value: "graphqlschema" },
  { label: "Groovy", value: "groovy" },
  { label: "HAML", value: "haml" },
  { label: "Handlebars", value: "handlebars" },
  { label: "Haskell", value: "haskell" },
  { label: "Haskell Cabal", value: "haskell_cabal" },
  { label: "haXe", value: "haxe" },
  { label: "Hjson", value: "hjson" },
  { label: "HTML", value: "html" },
  { label: "HTML (Elixir)", value: "html_elixir" },
  { label: "HTML (Ruby)", value: "html_ruby" },
  { label: "INI", value: "ini" },
  { label: "Io", value: "io" },
  { label: "Jack", value: "jack" },
  { label: "Jade", value: "jade" },
  { label: "Java", value: "java" },
  { label: "JavaScript", value: "javascript" },
  { label: "JSON", value: "json" },
  { label: "JSONiq", value: "jsoniq" },
  { label: "JSP", value: "jsp" },
  { label: "JSSM", value: "jssm" },
  { label: "JSX", value: "jsx" },
  { label: "Julia", value: "julia" },
  { label: "Kotlin", value: "kotlin" },
  { label: "LaTeX", value: "latex" },
  { label: "LESS", value: "less" },
  { label: "Lean", value: "lean" },
  { label: "Liquid", value: "liquid" },
  { label: "Lisp", value: "lisp" },
  { label: "LiveScript", value: "livescript" },
  { label: "LogiQL", value: "logiql" },
  { label: "LSL", value: "lsl" },
  { label: "Lua", value: "lua" },
  { label: "LuaPage", value: "luapage" },
  { label: "Lucene", value: "lucene" },
  { label: "Makefile", value: "makefile" },
  { label: "Markdown", value: "markdown" },
  { label: "Mask", value: "mask" },
  { label: "MATLAB", value: "matlab" },
  { label: "Maze", value: "maze" },
  { label: "MEL", value: "mel" },
  { label: "MUSHCode", value: "mushcode" },
  { label: "MySQL", value: "mysql" },
  { label: "Nix", value: "nix" },
  { label: "NSIS", value: "nsis" },
  { label: "Objective-C", value: "objectivec" },
  { label: "OCaml", value: "ocaml" },
  { label: "Pascal", value: "pascal" },
  { label: "Perl", value: "perl" },
  { label: "pgSQL", value: "pgsql" },
  { label: "PHP", value: "php" },
  { label: "Pig", value: "pig" },
  { label: "Powershell", value: "powershell" },
  { label: "Praat", value: "praat" },
  { label: "Prolog", value: "prolog" },
  { label: "Properties", value: "properties" },
  { label: "Protobuf", value: "protobuf" },
  { label: "Python", value: "python" },
  { label: "R", value: "r" },
  { label: "Razor", value: "razor" },
  { label: "RDoc", value: "rdoc" },
  { label: "RHTML", value: "rhtml" },
  { label: "RST", value: "rst" },
  { label: "Ruby", value: "ruby" },
  { label: "Rust", value: "rust" },
  { label: "SASS", value: "sass" },
  { label: "SCAD", value: "scad" },
  { label: "Scala", value: "scala" },
  { label: "Scheme", value: "scheme" },
  { label: "SCSS", value: "scss" },
  { label: "SH", value: "sh" },
  { label: "SJS", value: "sjs" },
  { label: "Smarty", value: "smarty" },
  { label: "snippets", value: "snippets" },
  { label: "Soy Template", value: "soy_template" },
  { label: "Space", value: "space" },
  { label: "SQL", value: "sql" },
  { label: "SQLServer", value: "sqlserver" },
  { label: "Stylus", value: "stylus" },
  { label: "SVG", value: "svg" },
  { label: "Swift", value: "swift" },
  { label: "Tcl", value: "tcl" },
  { label: "Tex", value: "tex" },
  { label: "Text", value: "text" },
  { label: "Textile", value: "textile" },
  { label: "Toml", value: "toml" },
  { label: "TSX", value: "tsx" },
  { label: "Twig", value: "twig" },
  { label: "Typescript", value: "typescript" },
  { label: "Vala", value: "vala" },
  { label: "VBScript", value: "vbscript" },
  { label: "Velocity", value: "velocity" },
  { label: "Verilog", value: "verilog" },
  { label: "VHDL", value: "vhdl" },
  { label: "Wollok", value: "wollok" },
  { label: "XML", value: "xml" },
  { label: "XQuery", value: "xquery" },
  { label: "YAML", value: "yaml" },
];

export default languages;


import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import { UserContext } from "../../context/UserContext";

function ExportSnippets(props) {
  const [ loading, setLoading ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ exportFile, setExportFile ] = useState(null);
  const successTimeout = useRef(false);
  const exportFileTimeout = useRef(false);
  const userContext = useContext(UserContext);
  const { userLoading, user } = userContext;

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
      clearTimeout(exportFileTimeout.current);
    }
  }, []);

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function exportSnippets(e) {
    setError(null);
    setLoading(true);

    http.get("/snippet/export/").then(resp => {
      console.log(resp);

      if(resp.data.success === false) {
        setError(resp.data.message);
      } else {
        setExportFile(resp.data.file);
        setSuccess(true);
        
        successTimeout.current = setTimeout(() => setSuccess(false), 5000);
        exportFileTimeout.current = setTimeout(() => setExportFile(null), 120000);
     
  
        
        
        //props.logAction("snippet", "export");
      }

      setLoading(false);
    }).catch((error) => {
      setError(getErrorMessage(error));
    });

    e.preventDefault();
  }

  return (
    <div className="scrollable-area more-padding">

      <form name="export_snippets_form" onSubmit={exportSnippets} className={`max-width-form alt-loading-position ${loading ? 'loading' : ''}`} noValidate>

        <div className="col-100 half-padding">

          <h3>Export Snippets</h3>
          <p>As a SnipSave Pro user you can export all of your snippets at any time. They will export and download as a zipped JSON file.</p>

        </div>

        <div className="col-100 half-padding">
          {formError()}
          <input type="submit" name="submit" value={success ? 'Complete!' : 'Export all snippets'} className={`btn ${success ? 'success' : ''}`} />
          {/*{exportFile ? <a href={exportFile} className="inline-alt-btn-link left-align">Download export file</a> : null}*/}
          {exportFile ? <Link to={`${process.env.REACT_APP_ZIPPED_FILE_BUCKET}/${user.username}_exported_snippets.json.zip`} target="_blank" className="inline-alt-btn-link left-align" download>Download export file</Link> : null}
        </div>

      </form>

    </div>
  );
}

export default ExportSnippets;

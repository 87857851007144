import React from "react";

const Banner = ({ title }) => {
  return (
    <section className="banner">
      <div className="inner-column">
        <h1 className="ng-cloak">{title}</h1>
      </div>
    </section>
  );
};

export default Banner;


import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";

function ChangeUsername(props) {
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [currentUsernameError, setCurrentUsernameError] = useState(false);
  const [usernameTakenError, setUsernameTakenError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const successTimeout = useRef(false);

  const form = useRef();
  const currentUsernameInput = useRef();

  // Clear the setTimeout(s) when the component is destroyed
  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
    };
  }, []);

  function handleInputChange({ target }) {
    const copyOfDataObject = { ...data, [target.name]: target.value };
    setData(copyOfDataObject);
  }

  function formError() {
    return error ? <div className="form-error">{error}</div> : null;
  }

  function submitUsername(event) {
    setError(null);
    setCurrentUsernameError(false);

    document.activeElement.blur();
    setProcessing(true);

    http
      .put("/user/change-username/", data)
      .then((resp) => {
        if (resp.data.success === false) {
          if (resp.data.message === "username taken") {
            setError("Username is Taken");
          }
        } else {
          form.current.reset();
          setSuccess(true);
          successTimeout.current = setTimeout(() => setSuccess(false), 4000);
          //props.logAction("user", "username_update");

          // Need to change my profile
          const viewProfileAnchor = document.getElementById("view-profile");
          viewProfileAnchor.href = process.env.REACT_APP_API_HOST + "/user/" + resp.data.username + "/";
        }
        setProcessing(false);
      })
      .catch((error) => {
        setError(getErrorMessage(error));
      });

    event.preventDefault();
  }

  return (
    <div className="scrollable-area more-padding">
      <form name="change_username_form" onSubmit={submitUsername} ref={form} className="max-width-form alt-loading-position">
        <div className="col-100 half-padding">
          <label htmlFor="change_username" className="label">
            Enter New Username
          </label>
          <input name="change_username" id="username" className="field" ref={currentUsernameInput} onChange={handleInputChange} />
        </div>
        <div className="col-100 half-padding centered">
          {formError()}
          <input
            type="submit"
            name="submit"
            value={success ? "Saved!" : "Save New Username"}
            className={`btn lg push-submit small-mobile-full ${success ? "success" : ""}`}
          />
        </div>
      </form>
    </div>
  );
}

export default ChangeUsername;


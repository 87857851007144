import React from "react";

const Benefits = () => {
  return (
    <div className="pb--md">

      <h3>Features and benefits of SnipSave Pro</h3>

      <p>
        <strong className="vs purple">Unlimited Private Snippets</strong><br/>
        These are completely private and hidden from everyone.
      </p>
      <p>
        <strong className="vs blue">Increased Rate Limits</strong><br/>
        Increased Rate Limits for the CLI, to 100 requests per 30 seconds.
      </p>
      <p>
        <strong className="vs orange-yellow">Unlimited Unlisted Snippets</strong><br/>
        These are only visible to people who have the link.
      </p>
      <p>
        <strong className="vs green">Export Snippets</strong><br/>
        Export and download all of your code snippets at any time.
      </p>
      <p>
        <strong className="vs blue">Support SnipSave</strong><br/>
        SnipSave is built and maintained by me, Wil, a solo developer. Your SnipSave Pro subscription helps me continue to improve the app. My mission is to help make your life and work as a developer easier.
      </p>

    </div>
  );
}

export default Benefits;


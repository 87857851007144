import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

// UI
import Header from "../components/Header";
import Banner from "../components/Banner";
import SignupForm from "../components/SignupForm";
import Footer from "../components/Footer";

const Signup = ({ setSection, logAction }) => {
  const location = useLocation();

  useEffect(() => {
    setSection("signup");
  }, [setSection]);

  // Redirect logged-in users to the main app
  if (localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
    return <Navigate to="/" />;
  }

  console.log("pages/Signup.js");

  return (
    <div>
      <Header section="signup" />
      <Banner title="Sign Up" />

      <section id="signup" className="signup-form-section">
        <div className="inner-column very-narrow">
          <SignupForm logAction={logAction} />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Signup;


import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Contexts
import { UserContext } from "../../context/UserContext";

// UI
import Loading from "../../components/Loading";
import SubscribeForm from "./subscription/SubscribeForm";
import SubscriptionDetails from "./subscription/SubscriptionDetails";

const ManageSubscription = (props) => {
  // Get user context
  const userContext = useContext(UserContext);
  const { userLoading, user } = userContext;

  const navigate = useNavigate();

  // User data loading
  if (userLoading) {
    return <Loading />;
  }

  return (
    <div className="scrollable-area">
      {user.pro ? <SubscriptionDetails logAction={props.logAction} /> : <SubscribeForm logAction={props.logAction} />}
    </div>
  );
};

export default ManageSubscription;


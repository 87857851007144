import React from "react";
import { Link } from "react-router-dom";

const SubscriptionCancelled = () => {
  return (
    <div className="scrollable-area more-padding">
      <div className="max-width-700 pb--md">
        <div className="col-100 half-padding">

          <h3>Your SnipSave Pro membership has been cancelled.</h3>

          <p><strong><em>Can you help me with a very quick favor?</em></strong></p>

          <p>
            I'm building and maintaining SnipSave myself as a solo developer, so every bit of feedback is incredibly helpful. Can you please take a moment to fill out the survey below? It would really mean a lot to me.
          </p>

          <p>
            Thank you!<br />
            Wil<br />
            <a href="mailto:support@snipsave.com" className="alt-link">support@snipsave.com</a>
          </p>

          <div className="hr less-top"></div>

          <iframe
            className="google-survey-iframe"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdnAlfxnO2Oym6nLzm10DmkP_XUHrYDkP39majFFKvZM7lnSQ/viewform?embedded=true"
            title="Cancellation Survey"
            width="640"
            height="1049"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>

        </div>
      </div>
    </div>
  );
};

export default SubscriptionCancelled;


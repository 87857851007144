import React from "react";

function FormattedDate({ children }) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const epoch = children;

  let dt = new Date(0);
  dt.setUTCSeconds(epoch / 1000);

  const fDate = `${months[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`;

  return fDate;
}

export default FormattedDate;

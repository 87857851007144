import React, { useContext, useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";

// Contexts
import { UserContext } from "./UserContext";

export const SnippetsContext = createContext();

export const SnippetsContextProvider = ({ children }) => {

  // Get user context
  const userContext = useContext(UserContext);
  const { setReloadUser } = userContext;

  const location = useLocation();

  const [ snippetsLoading, setSnippetsLoading ] = useState(true);
  const [ snippets, setSnippets ] = useState([]);
  const [ originalSnippets, setOriginalSnippets ] = useState([]);
  const [ rerenderSnippetList, setRerenderSnippetList ] = useState(null);
  const snippetsContext = { snippetsLoading, snippets, setSnippets, searchSnippets, getRedirectPath, addSnippet, rerenderSnippetList, setRerenderSnippetList, changeSnippetFolder };
  
  // Filter query params
  const queryParams = location.search;
  const params = new URLSearchParams(queryParams);

  // Load the snippets
  useEffect(() => {
    setSnippetsLoading(true);
    
    http.get(`/snippet/${queryParams}`).then(resp => {
      setSnippets(resp.data);

      // Save a copy of the originally loaded snippets so we can safely
      // search and filter and then reset to this list.
      setOriginalSnippets(resp.data);

      // This is the snippets list we can safely search filter on
      setSnippetsLoading(false);

    }).catch((error) => {
      console.error(getErrorMessage(error));
    });

  }, [queryParams]);

  function searchSnippets(searchText) {

    // Escape special regex characters within search text (i.e. $, /, \, etc.)
    searchText = searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

    const copyOfSnippets = originalSnippets.filter((snippet) => {
      const searchRegex = new RegExp(searchText, 'gi');
      let tags = "";
      
      if(snippet.tags && snippet.tags.length) {
        tags = snippet.tags.join(", ") || [];
      }

      return snippet.title.match(searchRegex) || snippet.language.match(searchRegex) || tags.match(searchRegex);
    });

    setSnippets(copyOfSnippets);
  }

  function addSnippet(newSnippet) {
    const copyOfOriginalSnippets = originalSnippets;
    copyOfOriginalSnippets.unshift(newSnippet);
    setOriginalSnippets(copyOfOriginalSnippets);
    setSnippets(copyOfOriginalSnippets); // <-- Should this be done? I'm unsure but tired right now. 10/16/2019.

    setRerenderSnippetList("snippet_added");
  }

  function changeSnippetFolder(snippet_id, folder_id) {

    // Save snippet into folder
    http.put(`/snippet/${snippet_id}/`, { folder: folder_id }).then(resp => {
          
      if(resp.data.success === false) {
        alert(resp.data.message);
      } else {

        // Update the snippet in the list of snippets
        const copyOfOriginalSnippets = originalSnippets;
        const droppedSnippet = copyOfOriginalSnippets.find(snippet => snippet.id === snippet_id);
        const droppedSnippetIndex = copyOfOriginalSnippets.findIndex((snippet, idx) => snippet.id === snippet_id);
        droppedSnippet.folder = folder_id;

        // If moving snippet to a folder not currently being viewed
        // then remove it from the folder being viewed.
        if(params.get("folder") && params.get("folder") !== folder_id) {
          copyOfOriginalSnippets.splice(droppedSnippetIndex, 1);
        }
        
        setOriginalSnippets(copyOfOriginalSnippets);
        setSnippets(copyOfOriginalSnippets);
        setRerenderSnippetList("snippet_edited");

        setReloadUser(true);
      }
      
    }).catch((error) => {
      alert(getErrorMessage(error));
    });
  }

  // Figure out where we should redirect the user to.
  // If they're in a Python language filter but created a new
  // JavaScript snippet, it's weird to keep them in the Python
  // filter when they save their new JavaScript snippet.
  function getRedirectPath(snippet) {

    const folder = params.get("folder");
    const language = params.get("language");
    const tag = params.get("tag");
    let redirectPath = "";

    if(folder) {
      if(snippet.folder) {
        redirectPath = `/snippet/${snippet.id}?folder=${snippet.folder}`;
      } else {
        redirectPath = `/snippet/${snippet.id}`;
      }
    } else if(language) {
      redirectPath = `/snippet/${snippet.id}?language=${snippet.language}`;
    } else if(tag && snippet.tags.indexOf(tag) > -1) {
      // Make sure the tag they're filtering by is still on the snippet,
      // otherwise redirect back to the main snippets list
      redirectPath = `/snippet/${snippet.id}?tag=${tag}`;
    } else {
      redirectPath = `/snippet/${snippet.id}`;
    }

    return redirectPath;
  }

  return <SnippetsContext.Provider value={snippetsContext}>{children}</SnippetsContext.Provider>;
};

export const { Consumer } = SnippetsContext;

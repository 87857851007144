const settings = {
  erroredInputClassName: "has-error",
  errorTextClassName: "field-error",
  errorTextDataAttribute: "data-error-text",
};

export default function checkForm(form, blurActiveElement, settingsOverride) {
  const mergedSettings = { ...settings, ...settingsOverride };
  resetForm(form, mergedSettings);

  const requiredInputs = form.querySelectorAll("input[required], textarea[required], select[required]");
  const urlRegex = /^[a-z]+:[^:]+$/i;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let errorsFound = false;

  requiredInputs.forEach((input) => {
    const val = input.value;

    if (!val || (input.type === "url" && !urlRegex.test(val)) || (input.type === "email" && !emailRegex.test(val))) {
      createError(input, mergedSettings);
      errorsFound = true;
    }
  });

  if (errorsFound) {
    return false;
  }

  if (blurActiveElement) {
    document.activeElement.blur();
  }

  return true;
}

export function createError(input, settings) {
  const errorMessage = input.getAttribute(settings.errorTextDataAttribute);
  input.classList.add(settings.erroredInputClassName);

  if (errorMessage) {
    const errorMessageNode = document.createElement("span");

    errorMessageNode.classList.add(settings.errorTextClassName);
    errorMessageNode.innerText = errorMessage;

    input.parentNode.insertBefore(errorMessageNode, input.nextSibling);
  }
}

export function resetForm(form, settings) {
  const erroredInputs = form.querySelectorAll("." + settings.erroredInputClassName);
  const errorMessageNodes = form.querySelectorAll("." + settings.errorTextClassName);

  erroredInputs.forEach((input) => {
    input.classList.remove(settings.erroredInputClassName);
  });

  errorMessageNodes.forEach((errorMessageNode) => {
    errorMessageNode.parentNode.removeChild(errorMessageNode);
  });
}


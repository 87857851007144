import React, { useState, useEffect, createContext } from "react";
import http, { getErrorMessage } from "../utility/http";
import axios from 'axios';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState({});
  const [reloadUser, setReloadUser] = useState(false);
  const userContext = { userLoading, user, setUser, setReloadUser };

  // Load the user
  useEffect(() => {
    const loadUser = async () => {
      try {
        const resp = await http.get("/user/");
        setUser(resp.data);
        setUserLoading(false);
        setReloadUser(false);
        //if (user.s3_photo_location) {
        //    
        //    const url = process.env.REACT_APP_PROFILE_PICTURE_BUCKET + user.s3_photo_location.small
        //    axios.get(url).then(resp => {
        //        if (resp.data.success == false) {
        //            console.error(resp.data.message);
        //        } else {
        //            console.log("downloading file");
        //        }
        //    })
        //    
        //}
      } catch (error) {
        console.error(getErrorMessage(error));
      }
    };

    loadUser();
  }, [reloadUser]);

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export const { Consumer } = UserContext;


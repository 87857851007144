import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";
import { UserContext } from "../../context/UserContext";
import { SnippetsContext } from "../../context/SnippetsContext";
import Loading from "../Loading";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AceEditor from "react-ace";
import languageOptions from "../LanguageOptions";
import privacyOptions from "../PrivacyOptions";
import "brace/theme/github";
import "../AceModes";
import infoIcon from "../../img/icon-info.svg";

const SnippetEdit = () => {
  //const history = useHistory();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { userLoading, user, setReloadUser } = userContext;

  const snippetsContext = useContext(SnippetsContext);
  const { setSnippets, snippets, getRedirectPath } = snippetsContext;

  const snippetID = window.location.pathname.split("/")[2];
  const saveButton = useRef();

  const initialData = {
    title: "",
    language: "text",
    content: "",
    folder: "",
    tags: [],
    privacy: "public",
  };

  const [data, setData] = useState(initialData);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({ label: "None", value: "" });
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({ label: "Text", value: "text" });
  const [selectedPrivacy, setSelectedPrivacy] = useState(privacyOptions[0]);
  const [error, setError] = useState(null);
  const [snippetEmpty, setSnippetEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!userLoading) {
      const loadedTagOptions = Object.keys(user.tags).map((tag) => ({ label: tag, value: tag }));
      setTagOptions(loadedTagOptions);
    }
  }, [userLoading, user.tags]);

  useEffect(() => {
    if (!userLoading) {
      const folderOptionList = [{ label: "None", value: "" }, ...user.folders.map((folder) => ({ label: folder.name, value: folder.id }))];
      setFolderOptions(folderOptionList);

      if (loading) {
        http.get(`/snippet/${snippetID}/`)
          .then((resp) => {
            if (resp.data.success === false) {
              //history.push(`/snippet/${snippetID}`);
              navigate(`/snippet/${snippetID}`);
            } else {
              const selectedLanguageOption = languageOptions.find((lang) => lang.value === resp.data.language);
              setSelectedLanguage(selectedLanguageOption);

              const selectedFolderOption = folderOptionList.find((folder) => folder.value === resp.data.folder);
              setSelectedFolder(selectedFolderOption);

              const selectedTagOptions = resp.data.tags.map((tag) => ({ label: tag, value: tag }));
              setSelectedTags(selectedTagOptions);

              const selectedPrivacyOption = privacyOptions.find((opt) => opt.value === resp.data.privacy);
              setSelectedPrivacy(selectedPrivacyOption);

              setData(resp.data);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            setError(getErrorMessage(error));
          });
      }
    }
  }, [user, userLoading, loading, snippetID]);

  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        saveButton.current.click();
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);

    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
    };
  }, [saveButton]);

  const handleInputChange = ({ target }) => {
    setData((prevData) => ({ ...prevData, [target.name]: target.value }));
  };

  const handleEditorChange = (newCode) => {
    setData((prevData) => ({ ...prevData, content: newCode }));
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    setData((prevData) => ({ ...prevData, language: selectedOption.value }));
  };

  const handleFolderChange = (selectedOption) => {
    setSelectedFolder(selectedOption);
    setData((prevData) => ({ ...prevData, folder: selectedOption.value }));
  };

  const addNewTagOption = (tag) => {
    const newTagObject = { label: tag, value: tag };

    const copyOfSelectedTags = selectedTags || [];
    copyOfSelectedTags.push(newTagObject);

    const copyOfTagOptions = tagOptions;
    copyOfTagOptions.push(newTagObject);

    const copyOfDataObject = { ...data };
    copyOfDataObject.tags.push(tag);

    setSelectedTags(copyOfSelectedTags);
    setTagOptions(copyOfTagOptions);
    setData(copyOfDataObject);
  };

  const handleTagsChange = (selectedOptions) => {
    const copyOfDataObject = { ...data };
    copyOfDataObject.tags = selectedOptions ? selectedOptions.map((tag) => tag.value) : [];

    setSelectedTags(selectedOptions);
    setData(copyOfDataObject);
  };

  const handlePrivacyChange = (selectedOption) => {
    const copyOfDataObject = { ...data };
    copyOfDataObject.privacy = selectedOption.value;

    setSelectedPrivacy(selectedOption);
    setData(copyOfDataObject);
  };

  const formError = () => (error ? <div className="form-error" ng-show="formError">{error}</div> : null);

  const submit = (event) => {
    setError(null);

    if (!data.content) {
      setSnippetEmpty(true);
    } else if (checkForm(event.target)) {
      setProcessing(true);

      http.put(`/snippet/${snippetID}/`, data)
        .then((resp) => {
          if (resp.data.success === false) {
            setProcessing(false);
            setError(resp.data.message);
          } else {
            const copyOfSnippetList = snippets.map((snippet) => {
              if (snippet.id === snippetID) {
                return {
                  ...snippet,
                  title: data.title,
                  language: data.language,
                  folder: data.folder,
                };
              }
              return snippet;
            });

            setSnippets(copyOfSnippetList);
            // Assuming logAction and setReloadUser functions are available
            // props.logAction("snippet", "edit");
            setReloadUser(true);

            navigate(getRedirectPath(data));
          }
        })
        .catch((error) => {
          setError(getErrorMessage(error));
        });
    }

    event.preventDefault();
  };

  if (loading || userLoading) {
    return <Loading />;
  }

  return (
    <section className="main light-bg no-padding">
      <div className="main-section">
        <div className="main-title-bar">
          <h3>Edit Snippet</h3>
        </div>
        <div className="main-section-content padded-bottom">
          <div className="scrollable-area more-padding">
            <form name="new_snippet_form" onSubmit={submit} noValidate className={`alt-loading-position ${processing ? 'loading' : ''}`}>
              {/* ... (rest of the form remains the same) */}
<div className="col-70 medium-mobile-100 half-padding">
                <label htmlFor="field_title" className="label">Title</label>
                <input autoComplete="off" name="title" type="text" id="field_title" className="field" autoFocus onChange={handleInputChange} defaultValue={data.title} required data-error-text="Please enter a snippet title" />
              </div>

              <div className="col-30 medium-mobile-100 half-padding select-language-wrapper">
                <label htmlFor="field_language" className="label">Language</label>
                <Select id="field_language" value={selectedLanguage} onChange={handleLanguageChange} options={languageOptions} className="react-select" classNamePrefix="react-select" />
              </div>

              <div className="col-100 half-padding">
                <label htmlFor="field_content" className="label">Code</label>
                <AceEditor className={snippetEmpty ? 'has-error' : ''} value={data.content} mode={data.language} theme="github" width="100%" height="340px" fontSize={13} showPrintMargin={false} highlightActiveLine={false} onChange={handleEditorChange} name="editor" editorProps={{$blockScrolling: true}} />
                {snippetEmpty ? <label className="control-label has-error validationMessage">Please enter some code</label> : null}
              </div>

              <div className="col-70 medium-mobile-100 half-padding select-folder-wrapper">
                <label htmlFor="field_folder" className="label">Folder</label>
                <Select id="field_folder" value={selectedFolder} onChange={handleFolderChange} options={folderOptions} className="react-select" classNamePrefix="react-select" />
              </div>

              <div className="col-30 medium-mobile-100 half-padding select-privacy-wrapper">
                <label htmlFor="field_privacy" className="label">
                  Privacy

                  <Link to="/settings/manage-subscription" className={`info-popup ${user.pro ? 'info-popup--hidden' : ''}`}>
                    <img src={infoIcon} alt="Info" />
                    <span className="text">
                      Upgrade to Pro for <strong>Private</strong> & <strong>Unlisted</strong> <span style={{top: "-1px"}}>snippets!</span>
                    </span>
                  </Link>

                </label>

                <Select id="field_privacy" value={selectedPrivacy} onChange={handlePrivacyChange} options={privacyOptions} isDisabled={!user.pro} className="react-select" classNamePrefix="react-select" />

              </div>

              <div className="col-100 medium-mobile-100 half-padding select-tags-wrapper">
                <label htmlFor="field_tags" className="label">Tags</label>
                <Creatable id="field_tags" value={selectedTags} onCreateOption={addNewTagOption} onChange={handleTagsChange} options={tagOptions} noOptionsMessage={() => 'No tags found'} isMulti className="react-select" classNamePrefix="react-select" />
              </div>

              <div className="col-100 half-padding centered dual-buttons">
                {formError()}
                <input type="submit" name="submit" value="Save Snippet" className="btn lg push-submit" ref={saveButton} />
                <Link to={`/snippet/${snippetID}`} id="cancel_add_snippet_btn" name="cancel" className="btn bordered dark-border lg push-submit">Cancel</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SnippetEdit;


import React from "react";

function Support() {
  return (
    <div className="scrollable-area more-padding">
      <div className="max-width-700 pb--md">
        <div className="col-100 half-padding">
          <h3>Contact Support & Feature Requests</h3>
          <p>I want to help you get the most out of SnipSave. Please reach out to me below via email or Twitter.</p>
          <p>You can send me feature requests, share issues or bugs you've run into, or just say hello and share your experience using SnipSave :)</p>
          <div className="hr less-top"></div>

          {/* <h3>Live Chat</h3>
          <p>Start a live chat session and I'll respond as soon as I can.</p>
          <p>
            <a href="https://www.tidio.com/talk/dyqkvjtyz47in2ztya11gseh9ftzhsyi" target="_blank" rel="noopener noreferrer" className="btn">
              Start a live chat
            </a>
          </p>
          <div className="hr"></div> */}

          <h3>Email</h3>
          <p>
            Email me with questions, technical issues, and feature requests:{" "}
            <a href="mailto:support@snipsave.com" className="dark">
              support@snipsave.com
            </a>
          </p>
          <p>
            <a href="mailto:support@snipsave.com" className="btn">
              Send me an email
            </a>
          </p>
          <div className="hr"></div>

          <h3>Twitter</h3>
          <p>
            Say hello on Twitter and follow{" "}
            <a href="https://twitter.com/SnipSave/" target="_blank" rel="noopener noreferrer" className="dark">
              @SnipSave
            </a>{" "}
            for product updates.
          </p>
          <p>
            <a href="https://twitter.com/SnipSave/" target="_blank" rel="noopener noreferrer" className="btn">
              Say hello on Twitter
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Support;
